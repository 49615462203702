import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import SearchBar from './SearchBar'
import { useRef, useEffect, useState, useCallback } from 'preact/hooks'
import { api } from '../api'
import { displayError } from '../errors'

export default function CustomerSearch({ setUserId }) {
	const [customers, setCustomers] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const rootRef = useRef();
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (!isActive) return;

		function escape(e) {
			if (!(rootRef.current as HTMLElement).contains(e.target)) {
				setIsActive(false);
			}
		}

		window.addEventListener('click', escape);

		return ()=> window.removeEventListener('click', escape);
	}, [isActive])

	const search = useCallback(async ({ signal, searchValue })=>{
		try {
			const res = await api({
				signal,
				type: 'admin',
				query: `
					query(
						$search: String!
					) {
						customers(
							limit: 8
							offset: 0
							options: {
								search: $search
							}
						) {
							data {
								user {
									id
									email
									firstName
									lastName
									phone
								}
							}
						}
					}
				`,
				variables: {
					search: searchValue,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setCustomers(res.data.customers.data);
		} catch (e) {
			if (e.name !== 'AbortError') {
				displayError(e);
	        }
		}
	},[])

	useEffect(()=> {
		const abortController = new AbortController();
		search({ signal: abortController.signal, searchValue});
		return ()=> abortController.abort();
	}, [searchValue]);

	function handleSubmit(e) {
		e.preventDefault();
	}

	return (
		<div ref={rootRef} className={css`
			position: relative;
		`}>
			<SearchBar 
				onSubmit={handleSubmit}
				placeholder='Customer'
				onFocus={()=> setIsActive(true)}
				value={searchValue}
				onInput={e => setSearchValue(e.currentTarget.value)}
			/>
			{
				isActive && customers.length > 0 && (
					<div className={css`
						display: grid;
						grid-template-columns: 1fr;
						position: absolute;
						top: 100%;
						left: 0;
						width: 275px;
						background: #fff;
						box-shadow: 0 5px 20px rgb(0 0 0 / 15%);
						border-radius: ${theme.corner.small};
						z-index: 1;
						overflow: hidden;
						margin-top: 0.5rem;
					`}>
						{
							customers.map(x => {
								let label = [];
								if (x.user.lastName) {
									if (x.user.firstName) {
										label.push(x.user.lastName+',')
									} else {
										label.push(x.user.lastName)
									}
								}
								if (x.user.firstName) {
									label.push(x.user.firstName);
								}
								if (x.user.email) {
									label.push('('+x.user.email+')')
								}
								if (!label.length) {
									label.push('Customer #'+x.user.id);
								}

								return (
									<button
										key={x.user.id}
										type='button'
										onClick={()=> {
											setUserId(x.user.id);
											setIsActive(false);
										}}
										className={cx(cn.unstyle, cn.truncate,css`
											padding: 0.3em 1em;

											&:first-child {
												padding-top: 0.8em;
											}

											&:last-child {
												padding-bottom: 0.8em;
											}

											&:hover {
												background: ${theme.color.link};
												color: #fff;
											}
										`)}
									>
										{label.join(' ')}
									</button>
								)
							})
						}
					</div>
				)
			}
		</div>
	)
}
