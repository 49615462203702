import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import Select from './Select'
import Input from './Input'
import Textarea from './Textarea'
import DateInput from './DateInput'
import Section from './Section'
import ErrorMessage from './ErrorMessage'
import { useState, useEffect, useCallback, useRef } from 'preact/hooks'
import arc from '../arc'
import { displayError } from '../errors'

function convertTimestampToUTC(localTimestamp) {
	const date = new Date(localTimestamp);
	return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
}

function convertToServerTimestamp(localTimestampString) { // todo: arcgrid UI also needs to use UTC / use servertime
	if (!localTimestampString) return null;
	const utc = convertTimestampToUTC(Number(localTimestampString));
	return Math.round(utc / 1000);
}

function convertToLocalTimestamp(serverTimeStamp) {
	if (!serverTimeStamp) return null;
	const d1 = new Date(serverTimeStamp * 1000);
	const d2 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate()); // todo: replace UTC with server time
	return d2.getTime();
}

function useRentalPeriod(rentalId) {
	const [items, setItems] = useState([]);

	useEffect(()=> {
		async function init() {
			try {
				const res = await arc(
					{
						service: 'cart',
						action: 'rental.Period.get',
						options: {
							filter: {
								rentalId,
							},
							limit: {
								count: 100,
								offset: 0
							},
							flag: {
								period: true
							}
						},
					}
				)

				if (res.data) {
					setItems(res.data);
				} else {
					throw new Error(res?.message || 'Unspecified error');
				}
			} catch (e) {
				displayError(e);
			}
		}

		if (rentalId) {
			init();
		}
	}, [rentalId]);

	return items;
}

function initCart(userId) {
	return arc({
		service: 'cart',
		action: 'lease.Cart.update',
		params: [userId],
	})
}

function getCart(userId) {
	return arc({
		service: 'cart',
		action: 'lease.Cart.get',
		options: {
			filter: {
				userId,
			},
			flag: {
				order: true,
				tag: true,
				user: true,
				user_billing: true,
			}
		}
	})
}

function createOrder (cartId) {
	return arc({
		service: 'cart',
		action: 'lease.Cart.createOrder',
		params: [
			cartId,
			null,
			null,
			'lease',
		],
	});
}

function updateOrder({cartId, orderIndex, value}) {
	return arc({
		service: 'cart',
		action: 'lease.cart.Order.update',
		params: [
			cartId,
			orderIndex,
		],
		options: {
			value,
		},
	});
}

function getArbitraryRentalAsset(rentalId) {
	return arc({
		service: 'cart',
		action: 'rental.Asset.get',
		params: [
		    {
		        filter: {
		            search: '',
		            rentalId,
		            statusCode: 'active'
		        },
		        flag: {
		            product: true // todo: is this needed?
		        },
		        limit: {
		            count: 1,
		            offset: 0
		        }
		    }
		]
	});
}

function purgeOrder(cartId, orderIndex) {
	return arc({
		service: 'cart',
		action: 'lease.cart.Order.purge',
		params: [
			cartId,
			orderIndex,
		],
	});
}

export default function LeaseReservationCheckout({ arcUserId, setCart }) {
	const rentalId = 1002; // todo: how not to hard-code this?
	const rentalPeriods = useRentalPeriod(rentalId);
	const [values, setValues] = useState({
		price: '',
		deposit: '',
		discountAmount: '',
		discountNote: '',
		periodId: '',
		startTime: '',
		endTime: '',
	});
	const [isBusy, setIsBusy] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [order, setOrder] = useState(null);
	const discountAmountRef = useRef(null);
	const discountNote = useRef(null);

	useEffect(()=>{
		async function init() {
			try {
				let res;

				res = await initCart(arcUserId);

				// Get the current cart with orders
				res = await getCart(arcUserId);

				if (!res?.success && res?.message) {
					throw new Error(res.message);
				}

				if (!res?.data?.length) {
					throw new Error('Failed to retrieve cart');
				}

				const cart = res.data[0];

				discountAmountRef.current = cart.discountAmountRef;
				discountNote.current = cart.discountNote;

				res = await getArbitraryRentalAsset(rentalId); // todo: data sync issue, need backend support for mapping actual slipID to the order

				if (!res?.data?.length) {
					throw new Error(res?.message || 'Failed to get rental asset');
				}

				const rentalAssetId = res.data[0].id;

				let mainOrder;
				let ordersToPurge = [];

				// To simpify the UX, I'm not supporting multi-order checkout at this time
				// Purge all orders except one that matches the resource ID
				Object.values(cart.orders).forEach(order => {
					if (!mainOrder && order.rentalAssetId === rentalAssetId && order.rentalId === rentalId) {
						mainOrder = order;
					} else {
						ordersToPurge.push(order.orderIndex);
					}
				});

				await Promise.all(
					ordersToPurge.map(orderIndex => purgeOrder(cart.id, orderIndex))
				).catch(e => {
					throw new Error(e);
				})

				// If no orders with rental ID, create order and add rental ID
				if (!mainOrder) {
					res = await createOrder(cart.id);

					if (!res?.data) {
						throw new Error(res?.message || 'Failed to create order');
					}

					mainOrder = res.data;

					// Update order to attach rentalAssetId
					res = await updateOrder({
						cartId: cart.id,
						orderIndex: mainOrder.orderIndex,
						value: {
							rentalId,
							rentalAssetId,
						},
					});

					if (!res?.data) {
						throw new Error(res?.message || 'Failed to update order');
					}

					mainOrder = res.data;
				}

				setValues({
					periodId: mainOrder.periodId,
					price: mainOrder.price,
					deposit: mainOrder.deposit,
					startTime: (convertToLocalTimestamp(mainOrder.startTime) || '').toString(),
					endTime: (convertToLocalTimestamp(mainOrder.endTime) || '').toString(),
					discountAmount: cart.discountAmount,
					discountNote: cart.discountNote,
				});

				setOrder(mainOrder);
			} catch (e) {
				displayError(e);
			}
		}

		init();
	},[])

	const handlePeriod = useCallback((e) => {
		setValues(values => {
			const periodId = e.currentTarget.value;
			const temp = {
				...values,
				price: '',
				periodId,
			};

			if (periodId) {
				const found = rentalPeriods.find(x => x.id.toString() === periodId);
				if (found) {
					temp.price = found.price;
				}
			}

			return temp;
		})
	},[rentalPeriods]);

	const handleInput = useCallback((e) => {
		setValues(values => {
			return {
				...values,
				[e.currentTarget.name]: e.currentTarget.value,
			}
		});
	}, []);

	const handleSubmit = async (e)=> {
		e.preventDefault();

		try {
			if (!order) {
				throw new Error('Order not found');
			}

			setIsBusy(true);
			setErrorMessage(null);

			let res;

			res = await updateOrder({
				cartId: order.cartId,
				orderIndex: order.orderIndex,
				value: {
					periodId: values.periodId,
					price: values.price,
					deposit: values.deposit,
					startTime: convertToServerTimestamp(values.startTime),
					endTime: convertToServerTimestamp(values.endTime),
				},
			});

			if (!res?.data) {
				throw new Error(res?.message || 'Failed to update order');
			}

			if (values.discountAmount != discountAmountRef.current || values.discountNote != discountNote.current) {
				res = await arc({
					service: 'cart',
					action: 'lease.Cart.update',
					params: [arcUserId],
					options: {
						value: {
							discountAmount: values.discountAmount,
							discountNote: values.discountNote,
						},
					},
				})

				if (!res?.data) {
					throw new Error(res?.message || 'Failed to update cart with discount information');
				}
			}

			// Get the updated cart for the totals
			res = await getCart(arcUserId);

			if (!res?.data?.length) {
				throw new Error(res?.message || 'Failed to retrieve cart');
			}

			const cart = res.data[0];

			setIsBusy(false);
			setCart(cart);
		} catch (e) {
			setErrorMessage(e.message);
			setIsBusy(false);
		}

	};

	const handleDate = useCallback((e)=> {
		setValues(values => {
			return {
				...values,
				[e.currentTarget.name]: e.currentTarget.value
			}
		})
	}, [])

	return (
		<form onSubmit={handleSubmit}>
			<div className={css`
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: 1fr;
				gap: ${theme.gutter.medium};
			`}>
				<div className={cn.h3}>Lease Checkout</div>
				<Section>
					<Select
						label='Lease Period'
						name='rentalPeriod'
						value={values.periodId}
						required={true}
						onChange={handlePeriod}
						options={[
							{
								value: '',
							},
							...rentalPeriods.map(({id, name}) => ({
								value: id,
								label: name,
							}))
						]}
					/>
					<Input
						label='Price'
						type='number'
						name='price'
						required={true}
						value={values.price}
						onInput={handleInput}
					/>
					<Input
						label='Deposit'
						type='number'
						name='deposit'
						value={values.deposit}
						onInput={handleInput}
					/>
					<Input
						label='Discount'
						type='number'
						name='discountAmount'
						value={values.discountAmount}
						onInput={handleInput}
					/>
					<DateInput
						label='Start Date'
						name='startTime'
						required={true}
						value={values.startTime}
						onClick={handleDate}
					/>
					<DateInput
						label='End Date'
						name='endTime'
						value={values.endTime}
						onClick={handleDate}
					/>
					<div className={css`grid-column: 1/-1;`}>
						<Textarea
							label='Discount Notes'
							name='discountNote'
							value={values.discountNote}
							onInput={handleInput}
						/>
					</div>
				</Section>
			</div>
			<div className={css`
				border-top: 1px solid ${theme.color.gray.lighter};
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: auto 1fr;
				gap: ${theme.gutter.small};
			`}>
				<button className={isBusy && cn.busy} disabled={!order}>Proceed to payment</button>
				<ErrorMessage message={errorMessage}/>
			</div>
		</form>
	)
}
