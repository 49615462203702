import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import LeaseReservationCheckoutPayment from './LeaseReservationCheckoutPayment'
import LeaseReservationCheckoutCart from './LeaseReservationCheckoutCart'
import { useState, useEffect, useCallback } from 'preact/hooks'

export default function LeaseReservationCheckout({ arcUserId, onSuccess }) {
	const [cart, setCart] = useState(null);

	return cart ? (
		<LeaseReservationCheckoutPayment initialCart={cart} onSuccess={onSuccess}/>
	) : (
		<LeaseReservationCheckoutCart arcUserId={arcUserId} setCart={setCart}/>
	)
}
