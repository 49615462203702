import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function Section({ title = null, children, cols = 3 }) {
	return (
		<div className={css`
			display: grid;
			grid-template-areas: 'header' 'body';
			grid-template-rows: auto 1fr;
		`}>
			{
				title && (
					<div className={cx(cn.h4, css`
						color: ${theme.color.teal};
						margin-bottom: 1rem;
						grid-area: header;
					`)}>
						{title}
					</div>
				)
			}
			<div
				style={{gridTemplateColumns: `repeat(${cols}, 1fr)`}}
				className={css`
					display: grid;
					gap: ${theme.gutter.medium};
					grid-area: body;
				`}
			>
				{children}
			</div>
		</div>
	)
}
