import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useState, useEffect, useCallback } from 'preact/hooks'
import { displayError } from '../errors'
import arc from '../arc'
import ErrorMessage from './ErrorMessage'
import Section from './Section'

function OrderSummary({ cart }) {
	return (
		<div>
			<div>Subtotal: ${cart.subTotal}</div>
			<div>Deposit Amount: ${cart.depositAmount}</div>
			<div>Discount Amount: ${cart.discountAmount}</div>
			<div>Tax: ${cart.taxAmount}</div>
			<div>Total: ${cart.total}</div>
		</div>
	)
}

export default function LeaseReservationCheckoutPayment({ initialCart, onSuccess }) {
	const [cart, setCart] = useState(initialCart);
	const [isBusy, setIsBusy] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	async function handleSubmit(e) {
		e.preventDefault();
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className={css`
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: 1fr;
				gap: ${theme.gutter.medium};
			`}>
				<div className={cn.h3}>Lease Payment</div>
				<Section title='Summary' cols={1}>
					<OrderSummary cart={cart}/>
				</Section>
			</div>
			<div className={css`
				border-top: 1px solid ${theme.color.gray.lighter};
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: auto 1fr;
				gap: ${theme.gutter.small};
			`}>
				<button className={isBusy && cn.busy}>Submit</button>
				<ErrorMessage message={errorMessage}/>
			</div>
		</form>
	)
}
