import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useErrors } from '../errors'
import ErrorMessage from './ErrorMessage'

export default function Alerts() {
	const errors = useErrors();

	return (
		<div className={css`
			position: fixed;
			bottom: ${theme.gutter.medium};
			left: 50%;
			transform: translate(-50%);
			display: grid;
			grid-template-columns: 1fr;
			gap: 10px;
			width: 600px;
			max-width: 90vw;
			z-index: ${theme.zIndex.error};
		`}>
			{
				errors.map(msg => (
					<ErrorMessage key={msg} message={msg} dismissable={true}/>
				))
			}
		</div>
	)
}
