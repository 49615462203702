import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useEffect, useRef } from 'preact/hooks'
import Label from './Label'

export default function Input({
	autoFocus = null,
	label = null,
	readOnly = null,
	useReadOnlyStyle = true,
	required = null,
	children = null,
	autocomplete = 'new-password',
	...rest
}) {
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (autoFocus && inputRef.current) {
			inputRef.current.focus()
		}
	}, [autoFocus])

	return (
		<Label label={label} required={required}>
			<div className={css`
				display: flex;
				gap: 0.25rem;
				min-width: 0;
			`}>
				<input
					{...rest}
					required={required}
					readOnly={readOnly}
					ref={inputRef}
					autocomplete={autocomplete}
					className={cx(cn.unset, css`
						cursor: pointer;
						padding: 0.8em 1.2em;
						border-radius: ${theme.corner.small};
						box-shadow: inset 0 0 0 1px ${theme.color.gray.light};
						flex-grow: 1;
						min-width: 0;

						&::placeholder {
							color: ${theme.color.gray.medium};
						}
					`, readOnly && useReadOnlyStyle && css`
						background: ${theme.color.gray.lighter};
					`)}
				/>
				{children}
			</div>
		</Label>
	)
}
