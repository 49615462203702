import create from 'zustand'

export const useStore = create(() => {
	const value = localStorage.getItem(_env.AUTH_STORAGE_KEY);
	let auth = {} as any;

	if (value) {
		try {
			auth = JSON.parse(value);
		} catch (e) {
			// ignore
		}
	}

	return {
		username: auth?.refreshExpiry && auth?.username && auth.refreshExpiry > (Date.now() / 1000) ? auth.username : false, // todo: replace Date.now with server time or time-from-refresh
	};
})

export async function signIn({ username, password }) {
	const res = await api({
		type: 'admin',
		variables: {
			username,
			password,
		},
		query: `
			mutation(
				$username: String!
				$password: String!
			) {
				login(
					username: $username
					password: $password
				) {
					token
					tokenExpiry
					refreshExpiry
				}
			}
		`,
	})

	if (res?.data?.login?.token) {
		localStorage.setItem(_env.AUTH_STORAGE_KEY, JSON.stringify({
			...res.data.login,
			username,
		}));
		useStore.setState({username})
		return true;
	}

	return false;
}

export async function signOut() {
	localStorage.removeItem(_env.AUTH_STORAGE_KEY);
	useStore.setState({username: null})
}

const refreshToken = (function(){
	let inFlightRequest;

	return function(authObj) {
		if (!inFlightRequest) {
			inFlightRequest = api({ // todo: error handling
				type: 'admin',
				variables: {
					token: authObj.token,
				},
				query: `
					mutation(
						$token: String!
					) {
						refreshToken(
							token: $token
						) {
							token
							tokenExpiry
							refreshExpiry
						}
					}
				`,
				token: authObj.token,
			}).then(res => {
				inFlightRequest = null;

				if (res?.data?.refreshToken) {
					localStorage.setItem(_env.AUTH_STORAGE_KEY, JSON.stringify({
						...res.data.refreshToken,
						username: authObj.username,
					}));

					return res.data.refreshToken.token;
				}

				return false;
			})
		}

		return inFlightRequest
	}
}())

export async function getToken() {
	const authStr = localStorage.getItem(_env.AUTH_STORAGE_KEY)
	let authObj;

	if (authStr) {
		try {
			authObj = JSON.parse(authStr);
		} catch (e) {
			// ignore
		}
	}

	if (authObj && authObj.tokenExpiry > (Date.now() / 1000)) {
		return authObj.token;
	}

	if (authObj && authObj.refreshExpiry > (Date.now() / 1000)) {
		let token = await refreshToken(authObj);

		if (token) {
			return token;
		}
	}

	signOut();
	return false;
}

export function useUsername() {
	return useStore(s => s.username);
}

export async function api({ type = '', query, variables = null, token = '', signal = null }) {
	const headers = {
		'Content-Type': 'text/plain',
	}

	const tokenStr = token || await getToken();

	if (tokenStr) {
		headers['Authorization'] = `Bearer ${tokenStr}`
	}

	const res = await fetch(type === 'admin' ? _env.ADMIN : _env.API, {
		method: 'POST',
		headers,
		signal,
		body: JSON.stringify({ query: query.replace(/\s+/g, ' ').trim(), variables }),
	})

	return res.json();
}