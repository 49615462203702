import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function PillBar({ items, pathname }) {
	const cnPill = css`
		color: ${theme.color.link};
		font-weight: 500;
		padding: 0.6em 1.2em;
		border-radius: ${theme.corner.small};
	`;

	const cnPillSelected = css`
		background: ${theme.color.link};
		color: #fff;
	`;

	return (
		<div className={css`
			display: flex;
			overflow: hidden;
			margin-right: auto;
		`}>
			{
				items.map(({
					href = null,
					onClick = null,
					text,
				}, i) => {
					const isSelected = pathname === href;

					return (
						<a key={i} href={href} className={cx(cnPill, isSelected && cnPillSelected)}>
							{text}
						</a>
					)
				})
			}
		</div>
	)
}