import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { api } from '../api'
import { useState, useEffect } from 'preact/hooks'
import Modal from './Modal'
import ErrorMessage from './ErrorMessage'
import Input from './Input'

export default function CreateUser({ onClose }) {
	const [isBusy, setIsBusy] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [isSuccess, setIsSuccess] = useState(false);
	const [values, setValues] = useState(()=> {
		return {
			email: '',
			password: '',
			confirmPassword: '',
			firstName: '',
			lastName: '',
		}
	});
	
	async function handleSubmit(e) {
		e.preventDefault();

		setErrorMsg(null);
		setIsBusy(true);

		try {
			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$email: String!
						$password: String!
					) {
						addUser(
							input: {
								email: $email
								password: $password
							}
						) {
							email
						}
					}
				`,
				variables: {
					email: values.email,
					password: values.password,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}
			setIsSuccess(true);
		} catch(e) {
			console.error(e);
			setErrorMsg(e.message);
			setIsBusy(false);
		}
	}

	function handleInput(e) {
		setValues(values => ({
			...values,
			[e.currentTarget.name]: e.target.value,
		}))
	}

	return (
		<Modal onClose={onClose} alignTop={true}>
			<div className={css`
				width: 500px;
			`}>
				{
					isSuccess ? (
						<div className={css`
							padding: ${theme.gutter.medium};
							display: grid;
							grid-template-columns: 1fr;
							gap: 1rem;
						`}>
							<div className={cx(cn.h4, css`color: ${theme.color.green};`)}>Success</div>
							<div>User created for <strong>{values.email}</strong></div>
						</div>
					) : (
						<form onSubmit={handleSubmit}>
							<div className={css`
								padding: ${theme.gutter.medium};
								display: grid;
								grid-template-columns: 1fr;
								gap: ${theme.gutter.medium};
							`}>
								<div className={cn.h3}>Create User</div>
								<Input
									type='email'
									name='email'
									label='Email'
									required={true}
									value={values.email}
									onInput={handleInput}
									/>
								<Input
									name='firstName'
									label='First Name'
									value={values.firstName}
									onInput={handleInput}
									/>
								<Input
									name='lastName'
									label='Last Name'
									value={values.lastName}
									onInput={handleInput}
									/>
								<Input
									type='password'
									name='password'
									label='Password'
									required={true}
									value={values.password}
									onInput={handleInput}
									/>
								<Input
									type='password'
									name='confirmPassword'
									label='Confirm Password'
									required={true}
									value={values.confirmPassword}
									onInput={handleInput}
									/>
							</div>
							<div className={css`
								border-top: 1px solid ${theme.color.gray.lighter};
								padding: ${theme.gutter.medium};
								display: grid;
								grid-template-columns: auto 1fr;
								gap: ${theme.gutter.small};
							`}>
								<button className={cx(cn.unstyle, cn.button.alt, isBusy && cn.busy)}>Save</button>
								<ErrorMessage message={errorMsg}/>
							</div>
						</form>
					)
				}
			</div>
		</Modal>
	)
}
