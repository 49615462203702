import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import svgCamera from '../embeds/camera-alt.svg';
import Compressor from 'compressorjs';
import svgTimes from '../embeds/times.svg';
import { displayError } from '../errors'

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

export default function PhotoUpload({ base64, fileName, onSelect, onDelete }) {
	async function handleFile(e) {
		let file = event.target.files[0];

		e.target.value = null; // clear the input so that re-selecting same file will trigger the handler again

		if (file) {
			try {
				// shrink files to keep total request size < 1MBs
				new Compressor(file, {
					quality: 0.6,
					maxWidth: 1000,
					maxHeight: 1000,
					success: async (blob) => {
						const base64 = await getBase64(blob);
						onSelect({
							base64,
							fileName: file.name,
						});
					},
					error(e) {
						displayError(e);
					},
				});
			} catch (e) {
				displayError(e);
			}
		}
	}

	return (
		<div className={css`
			position: relative;
			height: 225px;
			border-radius: 12px;
			background-color: ${theme.color.gray.lighter};
			display: grid;
			grid-template-columns: 1fr;
		`}>
			<label
				style={{backgroundImage: base64 ? `url("${base64}")` : null}}
				className={css`
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					cursor: pointer;
					user-select: none;
					color: ${theme.color.gray.medium};
					display: flex;
				`}
			>
				{!base64 && (
					<div
						className={css`
							margin: auto;
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
						`}
					>
						<div className={css`
							display: flex;

							svg {
								width: 50px;
								margin: auto;
							}
						`} dangerouslySetInnerHTML={{__html: svgCamera}}/>
						<div>Add a photo</div>
					</div>
				)}
				<input 
					style={{display: 'none'}} 
					type='file' 
					accept='image/*'
					// capture='environment'
					onChange={handleFile}
				/>
			</label>
			{
				base64 && (
					<button
						type='button'
						dangerouslySetInnerHTML={{__html: svgTimes}}
						className={cx(cn.unstyle, css`
							position: absolute;
							top: ${theme.gutter.small};
							right: ${theme.gutter.small};
							display: flex;

							svg {
								fill: ${theme.color.error};
								width: 1em;
								margin: auto;
							}
						`)}
						onClick={onDelete}
					/>
				)
			}
		</div>
	);
}