import { h } from 'preact'
import { displayError } from './errors'
import { api } from './api'
import { useEffect, useState, useCallback } from 'preact/hooks'

// todo: handle race-conditions

export default function useLeases({ startDateISO, endDateISO }) {
	const [leases, setLeases] = useState(null);
	const [isBusy, setIsBusy] = useState(false);

	const fetchLeases = useCallback(async ({ startDateISO, endDateISO }) => {
		const limit = 1000;
		let leases = [];

		setIsBusy(true);

		async function init(offset) {
			try {
				const options = {
					dates: {
						start: startDateISO,
						end: endDateISO,
					},
					excludeCanceled: true,
				};

				const res = await api({
					type: 'admin',
					query: `
						query(
							$limit: Int!
							$offset: Int!
							$options: leaseOptions
						) {
							leases(
								limit: $limit
								offset: $offset
								options: $options
							) {
								count
								data {
									dockSlipId
									startTime
									endTime
								}
							}
						}
					`,
					variables: {
						limit,
						offset,
						options,
					}
				});
				
				if (res?.errors?.length) {
					throw new Error(res.errors[0].message);
				}

				if (res?.data?.leases?.data) {
					leases = [...leases, ...res.data.leases.data];
				}

				if (res?.data?.leases?.count && (offset + limit < res.data.leases.count)) {
					init(offset + limit);
				} else {
					setLeases(leases);
					setIsBusy(false);
				}

			} catch (e) {
				setIsBusy(false);
				displayError(e);
			}
		}

		init(0);
	}, []);

	useEffect(()=> {
		fetchLeases({ startDateISO, endDateISO })
	}, [startDateISO, endDateISO])

	return {
		leases,
		isBusy,
	};
}