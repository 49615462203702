import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useState, useEffect, useRef } from 'preact/hooks'
import svgAccount from '../embeds/user-circle.svg'
import { signOut, useUsername } from '../api'
import CreateUser from './CreateUser'

function MenuButton({ children, onClick }) {
	return (
		<button
			type='button'
			className={cx(cn.unstyle, css`
				padding: 10px 20px;
				color: inherit;
				opacity: 0.9;

				&:hover {
					background: ${theme.color.gray.lighter};
				}
			`)}
			onClick={onClick}
		>{children}</button>
	)
}

export default function UserMenu() {
	const [isOpen, setIsOpen] = useState(false);
	const toggleRef = useRef<HTMLButtonElement>();
	const username = useUsername();
	const [createUser, setCreateUser] = useState(false);

	function toggle() {
		setIsOpen(!isOpen);
	}

	useEffect(()=>{
		function escape(e) {	
			if (!toggleRef.current.contains(e.target)) {
				setIsOpen(false);
			}
		}

		window.addEventListener('click', escape);

		return ()=> window.removeEventListener('click', escape);
	},[])

	return (
		<div className={css`
			position: relative;
			display: flex;
			justify-content: flex-end;
		`}>
			<button 
				onClick={toggle}
				ref={toggleRef}
				type='button'
				className={cx(cn.unstyle, css`
					display: flex;
					align-items: center;

					svg {
						width: 28px;
						fill: ${theme.color.gray.dark};
					}
				`)}
				dangerouslySetInnerHTML={{__html: svgAccount}}
			/>
			<div 
			className={cx(css`
				background: #fff;
				min-width: 240px;
				position: absolute;
				top: calc(100% + 1px);
				right: 0;
				display: grid;
				background: #fff;
				border-radius: ${theme.corner.medium};
				padding-bottom: 10px;
				z-index: ${theme.zIndex.menu};
				box-shadow: 0 5px 15px rgba(0,0,0,0.2);
			`, !isOpen && css`
				visibility: hidden;
				pointer-events: none;
			`)}>
				<div className={css`
					padding: 15px 20px;
					border-bottom: 1px solid ${theme.color.divider};
					margin-bottom: 10px;
					color: ${theme.color.gray.medium};
				`}>{username}</div>
				<MenuButton onClick={()=> setCreateUser(true)}>Create new user</MenuButton>
				<MenuButton onClick={signOut}>Sign out</MenuButton>
			</div>
			{
				createUser && <CreateUser onClose={()=> setCreateUser(false)}/>
			}
		</div>
	)
}
