import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function Label({ label, required = false, children }) {
	return (
		<div className={css`
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.2em;
		`}>
			{
				label && (
					<div>
						<span className={css`color: ${theme.color.gray.dark};`}>{label}</span>
						{required && (
							<sup
								className={css`
									color: ${theme.color.error};
									margin-left: 0.25em;
								`}
							>
								*
							</sup>
						)}
					</div>
				)
			}
			{children}
		</div>
	)
}
