import { h } from 'preact'
import { displayError } from './errors'
import { api } from './api'
import { useEffect, useState, useMemo } from 'preact/hooks'

export default function useSlips() {
	const [slips, setSlips] = useState(null);

	useEffect(()=>{
		const limit = 600;
		let slips = [];

		async function init(offset) {
			try {
				const res = await api({
					type: 'admin',
					query: `
						query(
							$limit: Int!
							$offset: Int!
						) {
							dockSlips(
								limit: $limit
								offset: $offset
							) {
								count
								data {
									id
									code
									dockId
									statusCode
									maintenanceStatusCode
									storageType
								}
							}
						}
					`,
					variables: {
						limit,
						offset,
					}
				});

				if (res?.errors?.length) {
					throw new Error(res.errors[0].message);
				}

				slips = [...slips, ...(res?.data?.dockSlips?.data || [])];

				if (res?.data?.dockSlips?.count && (offset + limit < res.data.dockSlips.count)) {
					init(offset + limit);
				} else {
					setSlips(slips);
				}

			} catch (e) {
				displayError(e);
			}
		}

		init(0);
	}, [])

	const slipsById = useMemo(()=>{
		if (!slips) return null;

		const obj = {};

		slips.forEach(slip => {
			obj[slip.id] = slip;
		})

		return obj;
	}, [slips])

	return {
		slips,
		slipsById,
		setSlips,
	};
}