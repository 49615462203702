import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import InfoItem from './InfoItem'

export default function InfoGrid({items}) {
	return (
		<div className={css`
			display: grid;
			grid-template-columns: auto auto;
			gap: ${theme.gutter.medium} 0;
		`}>
			{
				items.map(({label, value})=> {
					return (
						<div key={label} className={css`
							&:nth-child(odd) {
								padding-right: ${theme.gutter.medium};
							}
							&:nth-child(even) {
								padding-left: ${theme.gutter.medium};
								border-left: 1px solid ${theme.color.gray.lighter};
							}
						`}>
							<InfoItem label={label}>
								{
									(value == null || value === '') ? (
										<span className={css`color: ${theme.color.gray.light};`}>N/A</span>
									) : value
								}
							</InfoItem>
						</div>
					)
				})
			}
		</div>
	)
}
