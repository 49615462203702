import { h } from 'preact'
import { displayError } from './errors'
import { api } from './api'
import { useEffect, useState } from 'preact/hooks'

export default function useSlips() {
	const [docks, setDocks] = useState(null);

	useEffect(()=>{
		const limit = 600;
		let docks = [];

		async function init(offset) {
			try {
				const res = await api({
					type: 'admin',
					query: `
						query(
							$limit: Int!
							$offset: Int!
						) {
							docks(
								limit: $limit
								offset: $offset
							) {
								count
								data {
									id
									name
								}
							}
						}
					`,
					variables: {
						limit,
						offset,
					}
				});

				if (res?.errors?.length) {
					throw new Error(res.errors[0].message);
				}

				docks = [...docks, ...(res?.data?.docks?.data || [])];

				if (res?.data?.docks?.count && (offset + limit < res.data.docks.count)) {
					init(offset + limit);
				} else {
					setDocks(docks);
				}

			} catch (e) {
				displayError(e);
			}
		}

		init(0);
	}, [])

	return [docks, setDocks];
}