import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import Select from './Select'
import Input from './Input'
import Section from './Section'
import { api } from '../api'
import { useState, useEffect } from 'preact/hooks'
import ErrorMessage from './ErrorMessage'
import Confirm from './Confirm'
import { displayError } from '../errors'
import useDocks from '../use-docks'

const newSlip = {
	dockId: '', // Int!
	sizeType: '', // String!
	storageType: '', // String!
	statusCode: 'available' as 'available' | 'disabled' | 'leased' | 'rented',
	coveredStatusCode: '', // String!
	maintenanceStatusCode: '', // String!
	rotate: 0, // Float!
	xCoord: 0, // Float!
	yCoord: 0, // Float!
	width: null, // Float!
	length: null, // Float!
	height: null, // Float!
	depth: null, // Float
	standardRate: null, // Float
};

function DeleteButton({ id, onDeleteSlip }) {
	const [isBusy, setIsBusy] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	async function handleDelete() {
		setIsBusy(true);
		setErrorMessage(null);

		try {
			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$id: Int!
					) {
						dockSlipDelete(
							id: $id
						)
					}
				`,
				variables: {
					id,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setShowConfirm(false);
			setIsBusy(false);
			onDeleteSlip();
		} catch (e) {
			setIsBusy(false);
			setErrorMessage(e.message);
		}
	}

	return (
		<Fragment>
			<button type='button' onClick={()=> setShowConfirm(true)}>
				Delete
			</button>
			{
				showConfirm && (
					<Confirm
						isBusy={isBusy}
						onCancel={()=> setShowConfirm(false)}
						onConfirm={handleDelete}
						errorMessage={errorMessage}
					>
						Are you sure you want to delete this slip?
					</Confirm>
				)
			}
		</Fragment>
	)
}

export default function SlipEdit({ slipCode, slip = null, onSuccess, onDeleteSlip = null }) {
	const [errorMsg, setErrorMsg] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [docks] = useDocks();
	const [values, setValues] = useState(()=> {
		let values = {
			code: slipCode
		};
		if (slip) {
			Object.keys(newSlip).map(key => {
				values[key] = slip[key];
			})
		} else {
			values = {
				...values,
				...newSlip,
			};
		}
		return values;
	});

	async function submit(e) {
		e.preventDefault();

		setErrorMsg(null);
		setIsBusy(true);

		try {
			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$dockSlip: dockSlipInput!
					) {
						${slip ? 'dockSlipEdit' : 'dockSlipAdd'}(
							dockSlip: $dockSlip
							${slip ? `id: ${slip.id}` : ''}
						) {
							id
							code
						}
					}
				`,
				variables: {
					dockSlip: {
						...values,
						width: values.width ? Number(values.width) : 0, // todo: support blanking out
						length: values.length ? Number(values.length) : 0, // todo: support blanking out
						height: values.height ? Number(values.height) : 0, // todo: support blanking out
						depth: values.depth ? Number(values.depth) : 0, // todo: support blanking out
						standardRate: values.standardRate ? Number(values.standardRate) : 0, // todo: support blanking out
					},
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			onSuccess(slip ? slip.code : res.data.dockSlipAdd.code)
		} catch(e) {
			console.error(e);
			setErrorMsg(e.message);
			setIsBusy(false);
		}
	}

	function handleChange(e) {
		setValues(values => {
			return {
				...values,
				[e.currentTarget.name]: e.currentTarget.value,
			}
		});
	}

	function handleNumber(e) {
		setValues(values => {
			return {
				...values,
				[e.currentTarget.name]: Number(e.currentTarget.value),
			}
		});
	}

	return (
		<form onSubmit={submit} className={css`
		`}>
			<div className={css`
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: 1fr;
				gap: ${theme.gutter.medium};
			`}>
				<div className={css`
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 1rem;
				`}>
					<div className={cn.h3}>{slip ? 'Edit' : 'Create'} Slip {slipCode}</div>
					{
						slip && <DeleteButton id={slip.id} onDeleteSlip={onDeleteSlip}/>
					}
				</div>
				<Section>
					<Select
						name='dockId'
						label='Dock'
						value={values.dockId}
						onChange={handleNumber}
						options={[
							{
								value: '',
								disabled: true,
							},
							...(docks?.map(x => {
								return {
									value: x.id,
									label: x.name,
								}
							}) || [])
						]}
						required/>
					<Select
						name='coveredStatusCode'
						label='Type'
						value={values.coveredStatusCode}
						onChange={handleChange}
						options={[
							{
								value: '',
								disabled: true,
							},
							{
								value: 'covered',
							},
							{
								value: 'open',
							},
						]}
						required/>
					<Select
						name='sizeType'
						label='Size'
						value={values.sizeType}
						onChange={handleChange}
						options={[
							{
								value: '',
								disabled: true,
							},
							{
								value: 'large',
							},
							{
								value: 'medium',
							},
							{
								value: 'small',
							},
						]}
						required/>
					<Select
						name='storageType'
						label='Storage'
						value={values.storageType}
						onChange={handleChange}
						options={[
							{
								value: '',
								disabled: true,
							},
							{
								value: 'dry',
							},
							{
								value: 'wet',
							},
						]}
						required/>
					<Select
						name='maintenanceStatusCode'
						label='Maintenance'
						value={values.maintenanceStatusCode}
						onChange={handleChange}
						options={[
							{
								value: '',
								disabled: true,
							},
							{
								value: 'active',
							},
							{
								value: 'inactive',
							},
						]}
						required/>
					<Input
						name='width'
						label='Width'
						value={values.width}
						onInput={handleChange}
						type='number'
						step='any'
						required
					/>
					<Input
						name='length'
						label='Length'
						value={values.length}
						onInput={handleChange}
						type='number'
						step='any'
						required
					/>
					<Input
						name='height'
						label='Height'
						value={values.height}
						onInput={handleChange}
						type='number'
						step='any'
						required
					/>
					<Input
						name='depth'
						label='Depth'
						value={values.depth}
						onInput={handleChange}
						type='number'
						step='any'
					/>
					<Input
						name='standardRate'
						label='Standard Rate'
						value={values.standardRate}
						onInput={handleChange}
						type='number'
						step='any'
					/>
				</Section>
			</div>
			<div className={css`
				border-top: 1px solid ${theme.color.gray.lighter};
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: auto 1fr;
				gap: ${theme.gutter.small};
			`}>
				<button className={cx(cn.unstyle, cn.button.alt, isBusy && cn.busy)}>Save</button>
				<ErrorMessage message={errorMsg}/>
			</div>
		</form>
	)
}
