import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import monthNames from '../month-names'
import { useState, useRef, useEffect } from 'preact/hooks'
import svgChevron from '../embeds/chevron-right.svg'
import svgDoubleChevron from '../embeds/chevron-double-right.svg'
import svgCalendar from '../embeds/calendar-alt.svg'
import DatePicker from './DatePicker'
import { setDate, useStore } from '../store'

function Arrow({ onClick, direction, style }) {
	return (
		<button
			type='button'
			onClick={onClick}
			dangerouslySetInnerHTML={{__html: style === 'double' ? svgDoubleChevron : svgChevron}}
			aria-label={direction}
			className={cx(cn.unstyle, css`
				display: flex;
				align-items: center;
				color: ${theme.color.link};
				font-weight: 500;
				padding: 0 1em;
				background: ${theme.color.gray.lighter};
				border-radius: ${theme.corner.small};

				svg {
					height: 10px;
				}
			`, direction === 'Previous' && css`
				svg {
					transform: rotate(180deg);
				}
			`)}
		/>
	)
}

export default function DateMenu() {
	const [isOpen, setIsOpen] = useState(false);
	const date = useStore(s => s.date);

	function handlePrevDay() {
		let d = new Date(date);
		d.setDate(d.getDate()-1);
		setDate(d)
	}

	function handleNextDay() {
		let d = new Date(date);
		d.setDate(d.getDate()+1);
		setDate(d)
	}

	function handlePrevMonth() {
		let d = new Date(date);
		d.setMonth(d.getMonth()-1);
		setDate(d)
	}

	function handleNextMonth() {
		let d = new Date(date);
		d.setMonth(d.getMonth()+1);
		setDate(d)
	}

	function handleDate(e) {
		setDate(new Date(Number(e.target.value)));
		setIsOpen(false);
	}

	function handleClose() {
		setIsOpen(false);
	}

	return (
		<div className={css`
			position: relative;
		`}>
			<div className={css`
				display: grid;
				grid-template-columns: auto auto auto auto auto;
				gap: 4px;
			`}>
				<Arrow onClick={handlePrevMonth} direction='Previous' style='double'/>
				<Arrow onClick={handlePrevDay} direction='Previous' style='single'/>
				<button 
				type='button'
				onClick={()=> setIsOpen(true)}
				className={cx(cn.unstyle, css`
					display: flex;
					align-items: center;
					color: ${theme.color.link};
					font-weight: 500;
					padding: 0.6em 1em;
					background: ${theme.color.gray.lighter};
					border-radius: ${theme.corner.small};
					min-width: 24.5ch;
				`)}>
					{monthNames[date.getMonth()]}
					<div className={css`
						margin-left: 0.5em;
					`}>{date.getDate()},</div>
					<div className={css`
						margin-left: 0.5em;
						margin-right: auto;
					`}>{date.getFullYear()}</div>
					<div dangerouslySetInnerHTML={{__html: svgCalendar}} className={css`
						display: flex;
						align-items: center;
						margin-left: 0.7em;
						margin-top: -2px;

						svg {
							width: 14px;
						}
					`}/>
				</button>
				<Arrow onClick={handleNextDay} direction='Next' style='single'/>
				<Arrow onClick={handleNextMonth} direction='Next' style='double'/>
			</div>
			{
				isOpen && (
					<div onClick={handleClose} className={css`
						position: fixed;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						z-index: ${theme.zIndex.datePicker};
						background: hsla(0,0%,0%,0.2);
					`}/>
				)
			}
			{
				isOpen && (
					<div className={css`
						position: absolute;
						top: 100%;
						right: 0;
						z-index: ${theme.zIndex.datePicker};
						background: #fff;
						box-shadow: 0 5px 20px rgb(0 0 0 / 15%);
						padding: ${theme.gutter.medium};
						border-radius: ${theme.corner.medium};
					`}>
						<DatePicker value={date.getTime().toString()} onClick={handleDate}/>
					</div>
				)
			}
		</div>
	)
}
