import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import Label from './Label'

export default function Textarea({
	required = null,
	label = null,
	autocomplete = 'new-password',
	...rest
}) {
	return (
		<Label label={label} required={required}>
			<textarea
				{...rest}
				required={required}
				autocomplete={autocomplete}
				className={cx(cn.unset, css`
					cursor: pointer;
					padding: 0.8em 1.2em;
					border-radius: ${theme.corner.small};
					box-shadow: inset 0 0 0 1px ${theme.color.gray.light};
					height: 180px;
				`)}
			/>
		</Label>
	)
}
