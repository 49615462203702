import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { Chart } from 'chart.js';
import monthNames from '../month-names'
import { useEffect, useRef, useMemo } from 'preact/hooks'

export default function ContractChart({ leases, slipsById }) {
	if (!leases || !slipsById) return null;

	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(()=>{
		if (!slipsById || !leases) return; // todo: add teardown

		const ctx = canvasRef.current.getContext('2d');
		const numberOfMonths = 11;
		const today = new Date();

		const labels = [];
		const wetData = [];
		const dryData = [];

		for (var i = numberOfMonths; i >= 0; i--) {
			const rangeStart = new Date(today.getFullYear(), today.getMonth()-i).getTime();
			const rangeEnd = new Date(today.getFullYear(), today.getMonth()-i+1).getTime();
			const endOfYear = new Date(today.getFullYear()+1, 0).getTime();
			let wetTotal = 0;
			let dryTotal = 0;

			leases.forEach(lease => {
				let leaseStart = new Date(lease.startTime).getTime();
				let leaseEnd = lease.endTime ? new Date(lease.endTime).getTime() : endOfYear;

				if (
					(leaseEnd >= rangeStart) && // sanity check
					(leaseStart < rangeEnd) &&
					slipsById[lease.dockSlipId]
				) {
					if (slipsById[lease.dockSlipId].storageType === 'dry') {
						dryTotal += 1;
					}
					else if (slipsById[lease.dockSlipId].storageType === 'wet') {
						wetTotal += 1;
					}
				}
			})

			labels.push(monthNames[today.getMonth()-i]);
			wetData.push(wetTotal);
			dryData.push(dryTotal);
		}

		const data = {
			labels: labels,
			datasets: [
				{
					label: 'Wet',
					data: wetData,
					fill: false,
					borderColor: '#3ABECC',
					backgroundColor: '#3ABECC',
					tension: 0,
				},
				{
					label: 'Dry',
					data: dryData,
					fill: false,
					borderColor: '#E69F27',
					backgroundColor: '#E69F27',
					tension: 0,
				},
			],
		};

		const config = {
			type: 'line',
			data,
			options: {
				responsive: true,
				maintainAspectRatio: false,
				animation: {
			        duration: 0
			    },
				plugins: {
					legend: {
						labels: {
							align: 'right',
							boxWidth: 12
						},
					}
				}
			}
		} as any;

		const myChart = new Chart(ctx, config);
	},[leases, slipsById])

	return useMemo(()=> (
		<div className={css`
			position: relative;
			height: 350px;
		`}>
			<canvas ref={canvasRef}/>
		</div>
	), []);
}
