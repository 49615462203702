import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import Modal from './Modal'
import { useEffect, useRef } from 'preact/hooks'
import ErrorMessage from './ErrorMessage'

export default function Confirm({ children, onCancel, onConfirm, isBusy, errorMessage = null }) {
	return (
		<Modal onClose={onCancel}>
			<div className={css`
				display: grid;
				grid-template-columns: 1fr;
				gap: 1.5em;
				align-items: center;
				padding: ${theme.gutter.medium};
			`}>
				<div>{children}</div>
				{
					errorMessage && <ErrorMessage message={errorMessage}/>
				}
				<div className={css`
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 1em;

				`}>
					<button type='button' onClick={onCancel}>Cancel</button>
					<button type='button' onClick={onConfirm} className={cx(
						cn.unstyle,
						cn.button.danger,
						isBusy && cn.busy,
					)}>Delete</button>
				</div>
			</div>
		</Modal>
	)
}
