import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import Label from './Label'

export default function Select({
	options,
	label = null,
	required = null,
	children = null,
	autocomplete = 'new-password',
	...rest
}) {
	return (
		<Label label={label} required={required}>
			<div className={css`
				display: flex;
				gap: 0.25rem;
			`}>
				<select
					{...rest}
					style={{
						backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xNDA4IDcwNHEwIDI2LTE5IDQ1bC00NDggNDQ4cS0xOSAxOS00NSAxOXQtNDUtMTlsLTQ0OC00NDhxLTE5LTE5LTE5LTQ1dDE5LTQ1IDQ1LTE5aDg5NnEyNiAwIDQ1IDE5dDE5IDQ1eiIvPjwvc3ZnPg==")`
					}}
					autocomplete={autocomplete}
					className={cx(cn.unset, css`
						cursor: pointer;
						background-size: 14px, 100%;
						background-position: calc(100% - 0.5em) 50%, 0 0;
						background-repeat: no-repeat;
						padding: 0.8em calc(0.75em + 14px) 0.8em 1.2em;
						border-radius: ${theme.corner.small};
						box-shadow: inset 0 0 0 1px ${theme.color.gray.light};
					`)}
				>
					{
						options.map((o,i) => (
							<option key={i} value={o.value} disabled={o.disabled || null}>
								{o.label || o.value}
							</option>
						))
					}
				</select>
				{children}
			</div>
		</Label>
	)
}
