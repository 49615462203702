import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import OccupancyChart from './OccupancyChart'
import ContractChart from './ContractChart'
import Block from './Block'
import { useMemo } from 'preact/hooks'
import useLeases from '../use-leases'
import useSlips from '../use-slips'

export default function _Dashboard({ page, location }) {
	const { slips, slipsById } = useSlips();
	const { leases } = useLeases({
		startDateISO: useMemo(()=> 
			new Date(new Date().getFullYear(), 0).toISOString()
		, []),
		endDateISO: useMemo(()=> 
			new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1).toISOString()
		, []),
	})

	return (
		<div className={css`
			padding: ${theme.gutter.small};
			background: ${theme.color.gray.lighter};
		`}>
			{
				leases && (
					<div className={css`
						display: grid;
						gap: ${theme.gutter.small};
						grid-template-columns: 1fr 2fr;

						@media(max-width: ${theme.break.medium.max}) {
							grid-template-columns: 1fr;
						}
					`}>
						<Block title='Occupancy rates'>
							<OccupancyChart leases={leases} slipsById={slipsById}/>
						</Block>
						<Block title='Monthly contracts'>
							<ContractChart leases={leases} slipsById={slipsById}/>
						</Block>
					</div>
				)
			}
		</div>
	)
}

_Dashboard.pageData = async function ({}) {
	return {
		head: {
			title: 'Dashboard',
		},
	}
}
