import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import InfoGrid from './InfoGrid'

function capitalize(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function SlipCard({ slip }) {
	return (
		<div className={css`
			display: grid;
			grid-template-columns: 1fr;
			gap: ${theme.gutter.medium};
		`}>
			<InfoGrid items={[
				{
					label: 'Slip',
					value: slip.code,
				},
				{
					label: 'Dock',
					value: slip.dockName,
				},
				{
					label: 'Size',
					value: capitalize(slip.sizeType),
				},
				{
					label: 'Covered',
					value: slip.coveredStatusCode === 'covered' ? 'Yes' : 'No',
				},
				{
					label: 'Status',
					value: capitalize(slip.statusCode),
				},
				{
					label: 'Storage Type',
					value: capitalize(slip.storageType),
				},
				{
					label: 'Width',
					value: slip.width,
				},
				{
					label: 'Length',
					value: slip.length,
				},
				{
					label: 'Depth',
					value: slip.depth,
				},
				{
					label: 'Standard Rate',
					value: slip.standardRate,
				},
			]}/>
		</div>
	)
}