import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import svgSearch from '../embeds/search.svg'
import svgSpin from '../svg/spinner-third.svg'

export default function SearchBar({ onSubmit, ...rest }) {
	return (
		<form onSubmit={onSubmit} className={css`
			display: grid;
			grid-template-columns: 1fr auto;
			background: ${theme.color.gray.lighter};
			border-radius: ${theme.corner.small};
		`}>
			<input {...rest} className={css`
				all: unset;
				padding: 0.6em 0 0.6em 1em;
			`}/>
			<button className={cx(cn.unstyle, css`
				display: flex;
				align-items: center;
				padding: 0 0.7em;

				svg {
					width: 1.1em;
					fill: ${theme.color.link};
				}
			`)} dangerouslySetInnerHTML={{__html: svgSearch}}/>
		</form>
	)
}
