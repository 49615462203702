import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function LeaseReservationFinish({ lease, onSuccess }) {
	return (
		<div className={css``}>
			finish
		</div>
	)
}
