import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useStore } from '../store'
import monthNames from '../month-names'

export default function Legend() {
	return (
		<div className={css`
			position: absolute;
			bottom: 0;
			right: 0;
			padding: 12px;
			background: hsl(0,0%,100%);
			border-top-left-radius: ${theme.corner.medium};
			z-index: 1;
		`}>

			<div className={css`
				display: flex;
				gap: 15px;
			`}>
				{
					[
						['available', theme.color.green],
						['leased', theme.color.blue],
						['lease inactive', theme.color.purple],
						['lease pending', theme.color.pink],
						['lease reserved', theme.color.orange],
					].map(([label, color],i)=> (
						<div key={i} className={css`
							display: grid;
							align-items: center;
							gap: 5px;
							grid-template-columns: auto 1fr;
						`}>
							<div style={{backgroundColor: color}} className={css`
								width: 10px;
								height: 10px;
								border-radius: 2px;
							`}/>
							<div>{label}</div>
						</div>
					))
				}
			</div>
		</div>
	)
}
