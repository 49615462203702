import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function _Error404() {
	return (
		<div
			className={cn.container.page}
		>
			<h1 className={css`text-align: center;`}>Page Not Found</h1>
		</div>
	)
}

_Error404.pageData = function () {
	return {
		head: {
			title: 'Invalid URL',
			noindex: true,
			canonical: false,
		},
	}
}
