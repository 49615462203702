import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useEffect, useState, useRef, useCallback, useMemo } from 'preact/hooks'
import mapSvg from '../assets/map-new.svg'
import { useStore } from '../store'
import url from '../url'

const minScale = 1;

function addDataAttributes(map, _slipNodes) {
	// todo: might be better to do this unattached from DOM. same for attaching styles.
	let code = 0;
	Array.from(map.querySelectorAll('[id^=slip-')).forEach(x => {
		if (x.previousElementSibling === null) {
			code = Number(x.id.replace('slip-', ''));

			if (!Number.isInteger(code)) {
				throw new Error(`slip numbering issue with CODE: ${code}`);
			}
		} else {
			code -= 2;
		}

		x.setAttribute('data-slip', code+'');
		_slipNodes.current[code] = x;
	})
}

function addStylesToMap(map) {
	const style = document.createElement('style');
	style.type = 'text/css';
	style.appendChild(document.createTextNode(`
		svg {
			--scale: 1;
			--x: 0px;
			--y: 0px;

			display: block;
			position: absolute;
			top: 0;
			left: 0;
			backface-visibility: hidden;
			-webkit-transform-style: preserve-3d;
			transform: translate(var(--x), var(--y)) scale(var(--scale)) translate3d(0, 0, 0);
		}

		[data-slip] {
			cursor: pointer;
		}

		[data-slip]:hover {
			fill: magenta;
		}

		[data-slip-status=available] {
			fill: ${theme.color.green};
		}
		[data-slip-status=lease-active] {
			fill: ${theme.color.blue};
		}
		[data-slip-status=lease-inactive] {
			fill: ${theme.color.purple};
		}
		[data-slip-status=lease-reserved] {
			fill: ${theme.color.orange};
		}
		[data-slip-status=lease-pending] {
			fill: ${theme.color.pink};
		}
	`));
	map.querySelector('svg').appendChild(style);
}

function addPanning(map, handleClick) {
	let panX = 0;
	let panY = 0;
	const svg = map.querySelector('svg');
	let mouseMoved;

	function startDrag(e) {
		const startX = e.pageX;
		const startY = e.pageY;
		const tempX = panX;
		const tempY = panY;
		const dragTreshold = 5; // arbitrary choice
		mouseMoved = false;

		function dragMove(e) {	
			e.preventDefault();
			e.stopPropagation();

			mouseMoved = true;

			panX = e.pageX - startX + tempX;
			panY = e.pageY - startY + tempY;
			svg.style.setProperty('--x', panX + 'px');
			svg.style.setProperty('--y', panY + 'px');
		}
	
		function dragEnd(e) {
			// mouseMoved = false;
			map.removeEventListener('mousemove', dragMove);
			map.removeEventListener('mouseup', dragEnd);

		}
	
		map.addEventListener('mousemove', dragMove);
		map.addEventListener('mouseup', dragEnd);
	}

	map.addEventListener('mousedown', startDrag);

	map.addEventListener('click', (e)=>{
		if (!mouseMoved) {
			handleClick(e);
		}
	})
}

function addScrollZoom(map, _scale) {
	const svg = map.querySelector('svg');

	function zoom(e) {
		_scale.current = Math.max(_scale.current + (_scale.current * -e.deltaY/500), minScale);
		svg.style.setProperty('--scale', _scale.current);
	}

	map.addEventListener('wheel', zoom, {});
}

export default function Map({ slips, leases }) {
	const _scale = useRef(1);
	const _slipNodes = useRef({});
	const [map, setMapNode] = useState(null);
	const handleClick = useCallback((e)=>{
		let slipCode = null;
		let leaseId = null;

		if (e.target.hasAttribute('data-slip')) {
			slipCode = e.target.getAttribute('data-slip');
		}

		if (e.target.hasAttribute('data-lease-id')) {
			leaseId = Number(e.target.getAttribute('data-lease-id'));
		}

		url.replace('/', {
			slipCode,
			leaseId,
		})
	}, []);

	// Adds data attributes to the SVG
	useEffect(()=>{
		if (!map) return;

		addDataAttributes(map, _slipNodes);
		addStylesToMap(map);
		addPanning(map, handleClick);
		addScrollZoom(map, _scale);
	}, [map])

	// Adds status to SVG for styling purposes
	useEffect(()=>{
		if (!slips) return;
		if (!leases) return;
		if (!map) return;

		Object.entries(_slipNodes.current).forEach(([code, node]: [string, any]) => {
			if (slips[code]) {
				let status = 'available';
				let lease = leases[slips[code].id];

				// todo: rented status
				if (lease) {
					// todo: check to make sure not canceled status
					// todo: legend color codes for active inactive pending reserved canceled
					// todo: account for statusCode on dashboard

					if (lease.statusCode === 'active') {
						status = 'lease-active'
					}
					else if (lease.statusCode === 'inactive') {
						status = 'lease-inactive'
					}
					else if (lease.statusCode === 'reserved') {
						status = 'lease-reserved'
					}
					else if (lease.statusCode === 'pending') {
						status = 'lease-pending'
					}
				}

				node.setAttribute('data-slip-status', status);
			} else {
				node.removeAttribute('data-slip-status');
			}

			if (slips[code] && leases[slips[code].id]) {
				node.setAttribute('data-lease-id', leases[slips[code].id].id)
			} else {
				node.removeAttribute('data-lease-id');
			}
		})
	}, [slips, leases, map])

	return useMemo(()=> {
		return (
			<object 
			onLoad={e => setMapNode(e.currentTarget.contentDocument)}
			type='image/svg+xml'
			data={mapSvg}
			className={css`
				position: absolute;
				top: 0;
				left: 0;
				width: 102%;
				height: 102%;
				background-color: rgb(3,70,85);
			`}/>
		)
	}, []);
}
