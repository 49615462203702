import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import url from '../url'

export default function Pagination({location, count = 0, limit = 0}) {
    
	const page = Math.max(location.query.page ? Number(location.query.page) : 1, 1);
	const prevLink = count && page - 1 >= 1 ? location.pathname+url.stringify({...location.query, page: page-1}) : null;
	const nextLink = count && page + 1 <= Math.ceil(count / limit) ? location.pathname+url.stringify({...location.query, page: page+1}) : null;

	return (
        <div className={css`position: relative;`}>
            {
                (prevLink || nextLink) && (
                    <div className={css`
                        display: flex;
                    `}>
                        {prevLink && <a className={css `margin-right: auto;`} href={prevLink}>←Previous</a>}
                        {nextLink && <a className={css `margin-left: auto;`} href={nextLink}>Next→</a>}
                    </div>
                )
            }
        </div>
	)
}
