import create from 'zustand'

export const useStore = create(() => ({
	headerContent: null,
	date: new Date(),
}))

export function setHeaderContent(headerContent) {
	useStore.setState({headerContent})
}

export function setDate(date) {
	useStore.setState({date})
}
