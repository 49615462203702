import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useState } from 'preact/hooks'
import svgChevron from '../embeds/chevron-right.svg'
import monthNames from '../month-names';
import dayNames from '../day-names'

function getCalendar(date) {
	const d = new Date(date.getFullYear(), date.getMonth())
	const month = d.getMonth()
	const days = []

	// Pad calendar with days from the previous month, if the first of the month does not coincide with the first of the week.
	d.setDate(d.getDate() - d.getDay())

	// Days from previous month
	while (d.getMonth() !== month) {
		days.push({
			date: new Date(d.getTime()),
			month: month,
		})
		d.setDate(d.getDate() + 1)
	}

	// Days from current month
	while (d.getMonth() === month) {
		days.push({
			date: new Date(d.getTime()),
			month: month,
		})
		d.setDate(d.getDate() + 1)
	}

	// Days from upcoming month
	if (days.length % 7 !== 0) {
		for (let i = days.length % 7; i < 7; i++) {
			days.push({
				date: new Date(d.getTime()),
				month: month,
			})
			d.setDate(d.getDate() + 1)
		}
	}

	const weeks = []

	while (days.length) {
		weeks.push(days.splice(0, 7))
	}

	return weeks
}

export interface Props {
	maxDate?: Date
	value?: string
	onClick: any
	name: string
}

function Day({ date, month, onClick, selectedDate, todaysDate, name }) {
	const isSelected = selectedDate && selectedDate.getTime() === date.getTime();
	const isToday = todaysDate.getTime() === date.getTime();

	return (
		<div className={css``}>
			<button
				type='button'
				onClick={onClick}
				name={name}
				value={date.getTime()}
				className={cx(
					cn.unstyle,
					css`
						display: block;
						margin: auto;
						border-radius: ${theme.corner.small};
						width: 3.8ch;
						height: 3.8ch;
						text-align: center;
						line-height: 3.8ch;
					`,
					isToday && !isSelected &&
						css`
							background: hsl(0, 0%, 80%);
							color: #fff;
						`,
					isSelected ?
						css`
							background: ${theme.color.link};
							color: #fff;
						` : css`
							&:hover {
								background: hsl(0, 0%, 92%);
							}
						`,
				)}
			>
				{date.getDate()}
			</button>
		</div>
	)
}

function Arrow({ onClick, disabled = null, direction, label }) {
	return (
		<button
			type='button'
			onClick={onClick}
			disabled={disabled}
			aria-label={label}
			className={cn.unstyle}
		>
			<span
				dangerouslySetInnerHTML={{ __html: svgChevron }}
				aria-hidden={true}
				className={css`
					display: flex;
					align-items: center;
					justify-content: center;
					width: 2em;
					height: 2em;

					svg {
						width: 0.5em;
						flex-shrink: 0;
					}
				`}
				style={direction === 'prev' ? { transform: `rotate(180deg)` } : null}
			/>
		</button>
	)
}

export default function DatePicker({ onClick, value, name }: Props) {
	const todaysDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

	let selectedDate = null;

	if (value) {
		const temp = new Date(Number(value));
		selectedDate = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate());
	}

	const [date, setDate] = useState(selectedDate || todaysDate)

	function prevMonth() {
		setDate(new Date(date.getFullYear(), date.getMonth() - 1))
	}

	function nextMonth() {
		setDate(new Date(date.getFullYear(), date.getMonth() + 1))
	}

	return (
		<div
			className={css`
				color: #555;
			`}
		>
			<div
				className={css`
					display: grid;
					grid-template-columns: auto minmax(10ch, 20ch) auto;
					align-items: center;
					justify-content: center;
					margin: 0 auto;
				`}
			>
				<Arrow
					onClick={prevMonth}
					direction='prev'
					label='Previous Month'
				/>
				<div
					className={cx(css`
						font-weight: ${theme.type.bold};
						text-align: center;
					`)}
				>
					{monthNames[date.getMonth()]} {date.getFullYear()}
				</div>
				<Arrow
					onClick={nextMonth}
					direction='next'
					label='Next Month'
				/>
			</div>
			<div
				className={css`
					display: grid;
					grid-template-columns: repeat(7, 1fr);
					margin-top: 1rem;
				`}
			>
				{dayNames.map(day => (
					<abbr
						key={day}
						title={day}
						className={css`
							text-decoration: none;
							padding: 0.25em 0.4em;
							text-align: center;
							color: ${theme.color.gray.medium};
							font-weight: ${theme.type.semiBold};
						`}
					>
						{day.slice(0,3)}
					</abbr>
				))}
				{getCalendar(date).map((week, x) =>
					week.map((props, y) => (
						<Day
							key={`${x},${y}`}
							{...props}
							name={name}
							onClick={onClick}
							selectedDate={selectedDate}
							todaysDate={todaysDate}
						/>
					))
				)}
			</div>
		</div>
	)
}
