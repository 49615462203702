import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import monthNames from '../month-names'

function ProgressBar({ label, value, goal }) {
	return (
		<Fragment>
			<div>{label}</div>
			<div className={css`
				height: 1em;
				background: ${theme.color.gray.light};
				position: relative;
				border-radius: 4px;
			`}>
				<div style={{
					width: value+'%',
					backgroundImage: `linear-gradient(90deg, #83b8c3 0%, #83b8c3 60%, ${value >= goal ? '#41CE47' : 'rgba(222, 13, 27, 0.9)'} 100%)`,
				}} className={css`
					height: 100%;
					border-radius: 4px;
				`}/>
				<div style={{
					left: goal+'%',
				}} className={css`
					position: absolute;
					top: 50%;
					height: 130%;
					width: 3px;
					background: #000;
					transform: translate(-50%, -50%);
				`}/>
			</div>
			<div>
				<div className={css`
					font-weight: ${theme.type.semiBold};
				`}>{value}%</div>
				<div className={css`
					color: ${theme.color.gray.medium};
					font-size: ${theme.type.small};
				`}>Goal of {goal}%</div>
			</div>
		</Fragment>
	)
}

function Section({ title, data }) {
	return (
		<div>
			<div className={cx(cn.h4, css`
				color: ${theme.color.teal};
			`)}>{title}</div>
			<div className={css`
				margin-top: 1rem;
				display: grid;
				grid-template-columns: auto 1fr auto;
				align-items: center;
				gap: 1rem;
			`}>
				{
					data.map(({ label, value, goal },i) => (
						<ProgressBar key={i} label={label} value={value} goal={goal}/>
					))
				}
			</div>
		</div>
	)
}

function percentTimeLeased({ leases, slips, rangeStart, rangeEnd }) {
	const slipsById = {};
	slips.forEach(x => slipsById[x.id] = x);

	let leasedWetTime = 0;
	let totalPossibleWetTime = slips.length * (rangeStart - rangeEnd);

	leases.forEach(lease => {
		if (slipsById[lease.dockSlipId]) {
			let startTime = Math.max(new Date(lease.startTime).getTime(), rangeEnd);
			let endTime = lease.endTime ? Math.min(new Date(lease.endTime).getTime(), rangeStart) : rangeStart;
			let duration = endTime - startTime;
			if (duration > 0) {
				leasedWetTime += duration;
			}
		}
	})

	return Math.round((leasedWetTime / totalPossibleWetTime) * 100)
}

export default function OccupancyChart({ leases, slipsById }) {
	if (!slipsById || !leases) return null;

	const todayTime = new Date().getTime();
	const firstOfYearTime = new Date(new Date().getFullYear(), 0).getTime();
	const annualData = [
		{
			storageType: 'wet',
			title: 'Wet Slips',
			goal: 80,
		},
		{
			storageType: 'dry',
			title: 'Dry Slips',
			goal: 70,
		},
	].map(x => {
		const slips = Object.values(slipsById).filter(slip => slip.storageType === x.storageType);

		if (!slips.length) return;

		const percent = percentTimeLeased({
			leases,
			slips,
			rangeStart: todayTime,
			rangeEnd: firstOfYearTime,
		})

		return {
			label: x.title+` (${slips.length.toLocaleString()})`,
			value: percent,
			goal: x.goal,
		}
	}).filter(x => x);


	const today = new Date();
	const monthStartTime = new Date(today.getFullYear(), today.getMonth()).getTime();
	const monthEndTime = new Date(today.getFullYear(), today.getMonth()+1).getTime();
	const montlyData = [
		{
			storageType: 'wet',
			title: 'Wet Slips',
			goal: 80,
		},
		{
			storageType: 'dry',
			title: 'Dry Slips',
			goal: 70,
		},
	].map(x => {
		const slips = Object.values(slipsById).filter(slip => slip.storageType === x.storageType);
		
		if (!slips.length) return;

		const percent = percentTimeLeased({
			leases,
			slips,
			rangeStart: monthStartTime,
			rangeEnd: monthEndTime,
		})

		return {
			label: x.title+` (${slips.length.toLocaleString()})`,
			value: percent,
			goal: x.goal,
		}
	}).filter(x => x);

	return (
		<div>
			<Section title={monthNames[new Date().getMonth()]+' '+new Date().getFullYear()} data={montlyData}/>
			<div className={css`
				margin-top: ${theme.gutter.medium};
				padding-top: ${theme.gutter.medium};
				border-top: 1px solid ${theme.color.divider};
			`}/>
			<Section title={'Year to date'} data={annualData}/>
		</div>
	)
}