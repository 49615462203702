import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function Block({ title = null, children = null }) {
	return (
		<div className={css`
			background: #fff;
			border-radius: ${theme.corner.medium};
		`}>
			{
				title && (
					<div className={cx(cn.h3, css`
						padding: ${theme.gutter.small} ${theme.gutter.medium};
						border-bottom: 1px solid ${theme.color.divider};
					`)}>{title}</div>
				)
			}
			<div className={css`
				padding: ${theme.gutter.medium};
			`}>{children}</div>
		</div>
	)
}
