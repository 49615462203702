import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useRef, useEffect } from 'preact/hooks'
import { useImmer } from '../use-immer'
import { signIn } from '../api'
// import svgLogo from '../embeds/logo.svg'
import ErrorMessage from './ErrorMessage';

function Input({autoFocus = null, ...props}) {
	const nodeRef = useRef<HTMLInputElement>();

	useEffect(() => {
		if (autoFocus && nodeRef.current) {
			nodeRef.current.focus()
		}
	}, [autoFocus])

	return <input ref={nodeRef} className={cx(cn.unset, css`
		width: 100%;
		padding: 1em;
		border-radius: 2em;
		background: #fff;
	`)} {...props}/>
}

export default function SignIn() {
	const [state, setState] = useImmer({
		isBusy: false,
		error: null,
		values: {
			email: '',
			password: '',
		}
	})

	async function submit(e) {
		e.preventDefault();

		setState(d => {
			d.isBusy = true;
			d.error = null;
		});

		try {
			const success = await signIn({
				username: state.values.email,
				password: state.values.password,
			})

			if (!success) {
				throw new Error('Sign in failed')
			}
		} catch ({ message }) {
			setState(d => {
				d.isBusy = false;
				d.error = message;
			});
		}
	}

	function update(e) {
		setState(d => {
			d.values[e.target.name] = e.target.value;
		})
	}

	return (
		<form onSubmit={submit} className={css`
			height: 100vh;
			display: flex;
			background: #000;
			overflow: auto;
			padding: ${theme.gutter.medium};
		`}>
			<div className={css`
				display: grid;
				grid-template-columns: 1fr;
				gap: 20px;
				width: 400px;
				margin: auto;
			`}>
			{/*
				<div dangerouslySetInnerHTML={{__html: svgLogo}} aria-label='home' className={css`
					display: flex;
					align-items: center;
					color: inherit;
					margin: 0 auto;

					svg {
						width: 200px;
						fill: #fff;
					}
				`}/>
			*/}
				<div className={css`
					font-size: 26px;
					color: #fff;
					margin: 2rem auto 0;
				`}>Sign In</div>	
				<Input
					placeholder='Email'
					name='email'
					autocomplete='email'
					required={true}
					onInput={update}
					autoFocus/>
				<Input
					type='password'
					placeholder='Password'
					name='password'
					autocomplete='current-password'
					required={true}
					onInput={update}/>
				{
					state.error && <ErrorMessage message={state.error}/>
				}
				<button className={cx(
					cn.unstyle,
					css`
					width: 100%;
					background: ${theme.color.link};
					color: #fff;
					padding: 1em;
					border-radius: 2em;
					text-align: center;
				`, state.isBusy && cn.busy)}>Sign In</button>
			</div>
		</form>
	)
}