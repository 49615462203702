import { css, glob } from 'goober'

// load for favicon
require('./assets/favicon.svg')

function cx(...args) {
	return args.filter(x => x).join(' ');
}

const zIndex = {
	search: null,
	modal: null,
	error: null,
	menu: null,
	datePicker: null,
	signout: null,
};

Object.keys(zIndex).forEach((key,i) => {
	zIndex[key] = i+100; // arbitrary starting index
})

const theme = {
	zIndex,
	block: '15px',
	corner: {
		small: '6px',
		medium: '12px',
		large: '20px',
	},
	gutter: {
		small: 'var(--gutter-small)',
		medium: 'var(--gutter-medium)',
		large: 'var(--gutter-large)',
	},
	get color() {
		const gray = {
			darkest: 'hsl(340, 5%, 12%)', // 'hsl(340,5%,12%)',
			darker: 'hsl(340, 3%, 30%)',
			dark: 'hsl(340, 2%, 45%)',
			medium: 'hsl(340, 1%, 65%)',
			light: 'hsl(340, 1%, 85%)',
			lighter: 'hsl(340, 3%, 94%)',
			lightest: 'hsl(340, 3%, 97%)',
		};

		const blue = 'hsl(238, 82%, 62%)';

		return {
			blue,
			link: blue,
			teal: 'hsl(192, 44%, 49%)',
			red: 'hsl(351,80%,59%)',
			green: 'hsl(123, 59%, 40%)',
			orange: 'hsl(30, 100%, 50%)',
			purple: 'hsl(277, 100%, 50%)',
			pink: 'hsl(316, 100%, 50%)',
			alert: 'hsl(38, 79%, 53%)',
			error: 'hsl(356, 69%, 56%)',
			lightError: 'hsl(356, 69%, 94%)',
			text: 'hsl(0,0%,20%)',
			gray,
			divider: gray.lighter,
		}
	},
	type: {
		s1: 'var(--type-s1)',
		s2: 'var(--type-s2)',
		s3: 'var(--type-s3)',
		s4: '16px',
		root: '14px',
		small: '13px',
		bold: '700',
		semiBold: '500',
	},
	get break() {
		/*
			Breakpoints have a min and a max
			Use a max when doing @media(max-width), e.g. @media(max-width: ${theme.break.medium.max})
			Use a min value when doing a @media(min-width)
			This allows the breaks-points to butt right up against each other without any overlap.
			Using pixels as the unit of measurement as it allows the minimum incremental change between 
			break-points (1px), without needing calc() support in media queries (limited browser support).
		*/
		const xsmall = 460
		const small = 770
		const medium = 950
		const large = 1290
		const xlarge = 1700
		return {
			xsmall: {
				min: 0 + 'px',
				max: xsmall + 'px',
			},
			small: {
				min: xsmall + 1 + 'px',
				max: small + 'px',
			},
			medium: {
				min: small + 1 + 'px',
				max: medium + 'px',
			},
			large: {
				min: medium + 1 + 'px',
				max: large + 'px',
			},
			xlarge: {
				min: large + 1 + 'px',
				max: xlarge + 'px',
			},
		}
	},
};

const focus = `
	&:focus-visible {
		outline: 2px solid ${theme.color.link};
	}

	&:focus:not(:focus-visible) { outline: none; }

`
const h1 = `
	font-size: ${theme.type.s1};
	font-weight: 700;
	line-height: 1.1;
`
const h2 = `
	font-size: ${theme.type.s2};
	font-weight: 700;
	line-height: 1.1;
`
const h3 = `
	font-size: ${theme.type.s3};
	font-weight: 500;
	line-height: 1.2;
`
const h4 = `
	font-size: ${theme.type.s4};
	font-weight: 500;
`

const buttonBase = `
	cursor: pointer;
	display: inline-block;
	user-select: none;
	border-radius: ${theme.corner.small};
	padding: 0.8em 1.2em;
	text-align: center;
	font-weight: 500;
`;

const cn = {
	unset: css`
		all: unset;
		display: revert;
		box-sizing: border-box;
		${focus}
	`,
	container: {
		small: css`
			width: 100%;
			max-width: ${theme.break.small.max};
			margin-left: auto;
			margin-right: auto;
		`,
		medium: css`
			width: 100%;
			max-width: ${theme.break.medium.max};
			margin-left: auto;
			margin-right: auto;
		`,
		large: css`
			width: 100%;
			max-width: ${theme.break.large.max};
			margin-left: auto;
			margin-right: auto;
		`,
		xlarge: css`
			width: 100%;
			max-width: ${theme.break.xlarge.max};
			margin-left: auto;
			margin-right: auto;
		`,
		page: css`
			width: 100%;
			max-width: ${theme.break.xlarge.max};
			margin-left: auto;
			margin-right: auto;
			padding: 70px ${theme.gutter.medium} ${theme.gutter.medium}; /* top padding needs to clear search bar overlap */
		`,
	},
	small: css`font-size: ${theme.type.small};`,
	bold: css`font-weight: ${theme.type.bold};`,
	h1: css(h1),
	h2: css(h2),
	h3: css(h3),
	h4: css(h4),
	unstyle: 'unstyle',
	button: {
		normal: 'button',
		alt: 'button',
		danger: cx('button', css`
			color: ${theme.color.error};
		`)
	},
	input: css`
		padding: 0.5em 0.8em;
		border-radius: ${theme.corner.small};
		border: 1px solid ${theme.color.gray.light};
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
		background: #fff;
	`,
	truncate: css`
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`,
	quadGrid: css`
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 4rem 12px;

		@media(max-width: ${theme.break.small.max}) {
			grid-template-columns: 1fr 1fr;
		}
	`,
	busy: css`
		cursor: default;
		position: relative;
		overflow: hidden;

		@keyframes BusyButton {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(359deg);
			}
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: inherit;
			z-index: 1;
		}

		&:after {
			color: inherit;
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			border: 2px solid transparent;
			border-bottom-color: currentColor;
			border-left-color: currentColor;
			border-right-color: currentColor;
			animation: BusyButton 0.8s infinite linear;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -10px;
			margin-top: -10px;
			z-index: 1;
		}
	`,
};

[300,400,500,700].forEach(weight => {
	glob`/* latin */
	@font-face {
	  font-family: 'Ubuntu';
	  font-style: normal;
	  font-weight: ${weight};
	  font-display: block;
	  src: url("${require(`./assets/Ubuntu-${weight}.woff2`)}") format('woff2');
	  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}`;
})

glob`
	:root {
		--type-s1: 32px;
		--type-s2: 26px;
		--type-s3: 20px;

		--gutter-large: 60px;
		--gutter-medium: 24px;
		--gutter-small: 10px;
	}

	@media(max-width: ${theme.break.medium.max}) {
		:root {
			--gutter-large: 45px;
		}
	}

	@media(max-width: ${theme.break.small.max}) {
		:root {
			--gutter-large: 30px;
		}
	}

	* {
		${focus}
	}

	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	svg {
		fill: currentColor;
	}

	html, input, button, select, textarea, input:-webkit-autofill::first-line {
		font-size: ${theme.type.root};
		font-family: 'Ubuntu', system-ui, sans-serif;
		font-weight: 400;
		line-height: 1.5;
	}

	button {
		border: unset;
		background: unset;
		padding: unset;
		color: unset;
		text-align: unset;
		cursor: pointer;
		user-select: none;
	}

	button:not(.unstyle), .button {
		${buttonBase}
		background: ${theme.color.gray.lighter} linear-gradient(#fff, ${theme.color.gray.lighter});

		box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);

		&[disabled] {
			opacity: 0.5;
		}

		&:not([disabled]):hover {
			color: #fff;
			background: ${theme.color.link};
		}
	}

	a, .link {
		color: ${theme.color.link};
		text-decoration: none;
	}

	body {
		background: #fff;
		color: ${theme.color.text};
		margin: 0;
		padding: 0;
		overscroll-behavior: none;
	}

	strong {
		font-weight: ${theme.type.bold};
	}

	h1 {
		${h1}
		margin: 2rem 0 0;
	}
	h2 {
		${h2}
		margin: 2rem 0 0;
	}
	h3 {
		${h3}
		margin: 2rem 0 0;
	}
	h4 {
		${h4}
		margin: 0;
	}
`

export { theme, cn, cx, css };