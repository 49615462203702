import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import InfoGrid from './InfoGrid'

export default function SummaryBoat({ lease }) {
	return (
		<InfoGrid items={[
			{
				label: 'Boat Name',
				value: lease.boatName,
			},
			{
				label: 'Boat Make',
				value: lease.boatMake,
			},
			{
				label: 'Boat Number',
				value: lease.boatNumber,
			},
			{
				label: 'Boat Length',
				value: lease.boatLength,
			},
			{
				label: 'Boat Year',
				value: lease.boatYear,
			},
			{
				label: 'Boat Type',
				value: lease.boatType,
			},
			{
				label: 'Name of Insured Person',
				value: lease.insuredName,
			},
			{
				label: 'Insurance Company Name',
				value: lease.insurerName,
			},
			{
				label: 'Insurance Company Phone',
				value: lease.insurerPhone,
			},
			{
				label: 'Policy Number',
				value: lease.insurerPolicyNumber,
			},
		]}/>
	)
}
