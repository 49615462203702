import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useRef, useEffect, useState, useCallback } from 'preact/hooks'
import { api } from '../api'
import { displayError } from '../errors'
import Block from './Block'
import LeaseManage from './LeaseManage'
import url from '../url'
import formatDate from '../format-date'
import Pagination from './Pagination'
import Select from './Select'
import Input from './Input'
import DateInput from './DateInput'
import svgTimes from '../embeds/times.svg'

const limit = 50;

function CustomerSearch({ userId, setUserId, onDismiss }) {
	const [customers, setCustomers] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const rootRef = useRef();
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (!isActive) return;

		function escape(e) {
			if (!(rootRef.current as HTMLElement).contains(e.target)) {
				setIsActive(false);
			}
		}

		window.addEventListener('click', escape);

		return ()=> window.removeEventListener('click', escape);
	}, [isActive])

	const search = useCallback(async ({ signal, searchValue })=>{
		try {
			const res = await api({
				signal,
				type: 'admin',
				query: `
					query(
						$search: String!
					) {
						customers(
							limit: 8
							offset: 0
							options: {
								search: $search
							}
						) {
							data {
								user {
									id
									email
									firstName
									lastName
									phone
								}
							}
						}
					}
				`,
				variables: {
					search: searchValue,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setCustomers(res.data.customers.data);
		} catch (e) {
			if (e.name !== 'AbortError') {
				displayError(e);
	        }
		}
	},[])

	useEffect(()=> {
		const abortController = new AbortController();
		search({ signal: abortController.signal, searchValue});
		return ()=> abortController.abort();
	}, [searchValue]);

	function handleSubmit(e) {
		e.preventDefault();
	}

	return (
		<div ref={rootRef} className={css`
			position: relative;
		`}>
			<Input
				label='Customer'
				placeholder='Customer name or email...'
				onFocus={()=> setIsActive(true)}
				value={searchValue}
				onInput={e => setSearchValue(e.currentTarget.value)}
			>
				{
					userId && <button type='button' onClick={onDismiss} className={cx(cn.unstyle, cn.button.normal, css`
						display: grid;
						grid-template-columns: auto auto;
						align-items: center;
						gap: 0.5em;
					`)}>
						<div>ID: {userId}</div>
						<div className={css`
							display: flex;
							align-items: center;
							svg {
								width: 0.7em;
							}
						`} dangerouslySetInnerHTML={{__html: svgTimes}}/>
					</button>
				}
			</Input>
			{
				isActive && customers.length > 0 && (
					<div className={css`
						display: grid;
						grid-template-columns: 1fr;
						position: absolute;
						top: 100%;
						left: 0;
						width: 275px;
						background: #fff;
						box-shadow: 0 5px 20px rgb(0 0 0 / 15%);
						border-radius: ${theme.corner.small};
						z-index: 1;
						overflow: hidden;
						margin-top: 0.5rem;
					`}>
						{
							customers.map(x => {
								let label = [];
								if (x.user.lastName) {
									if (x.user.firstName) {
										label.push(x.user.lastName+',')
									} else {
										label.push(x.user.lastName)
									}
								}
								if (x.user.firstName) {
									label.push(x.user.firstName);
								}
								if (x.user.email) {
									label.push('('+x.user.email+')')
								}
								if (!label.length) {
									label.push('Customer #'+x.user.id);
								}

								return (
									<button
										key={x.user.id}
										type='button'
										onClick={()=> {
											setUserId(x.user.id);
											setIsActive(false);
											setSearchValue('');
										}}
										className={cx(cn.unstyle, cn.truncate,css`
											padding: 0.3em 1em;

											&:first-child {
												padding-top: 0.8em;
											}

											&:last-child {
												padding-bottom: 0.8em;
											}

											&:hover {
												background: ${theme.color.link};
												color: #fff;
											}
										`)}
									>
										{label.join(' ')}
									</button>
								)
							})
						}
					</div>
				)
			}
		</div>
	)
}

export default function _Leases({ location }) {
	const [searchValue, setSearchValue] = useState('');
	const [leases, setLeases] = useState([]);
	const [editLease, setEditLease] = useState(null);	
	const [count, setCount] = useState(null);

	const search = useCallback(async ()=>{
		const query = location.query;

		try {
			const options = {} as any;
			const page = Math.max(query.page ? Number(query.page) : 1, 1);
			const offset = page * limit - limit;

			if (query.userId) {
				options.userId = query.userId;
			}

			if (query.q) {
				options.boatName = query.q;
			}

			if (query.status) {
				options.statusCode = query.status;
			}

			if (query.start && query.end) { // todo: use OR once API supports it
				options.dates = {};
				if (query.start) {
					options.dates.start = new Date(Number(query.start)).toISOString()
				}
				if (query.end) {
					options.dates.end = new Date(Number(query.end)).toISOString()
				}
			}

			const res = await api({
				type: 'admin',
				query: `
					query(
						$offset: Int!
						$options: leaseOptions
					) {
						leases(
							limit: 50
							offset: $offset
							options: $options
						) {
							count
							data {
								id
								marinaId
								userId
								dockSlipId
								purchaseOrderCode
								startTime
								endTime
								boatName
								boatType
								boatMake
								boatYear
								boatNumber
								boatLength
								boatUrl
								insurerName
								insuredName
								insurerPolicyNumber
								insurerPhone
								photos
								note
								statusCode
								createdOn
								updatedOn
								user {
									id
									email
									phone
									firstName
									lastName
								}
							}
						}
					}
				`,
				variables: {
					offset,
					limit,
					options,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setLeases(res.data.leases.data);
			setCount(res.data.leases.count);
		} catch (e) {
			displayError(e);
		}
	}, [location.query])

	useEffect(()=>{
		search();
	}, [location.query])

	function handleChange(e) {
		url.replace(location.pathname, {
			...location.query,
			page: null,
			[e.currentTarget.name]: e.currentTarget.value || null,
		})
	}

	function setUserId(userId) {
		url.replace(location.pathname, {
			...location.query,
			page: null,
			userId,
		})
	}

	const cnRow = css`
		display: grid;
		grid-template-columns: 10ch 10ch 1fr 1fr 1fr 1fr 10ch 10ch 10ch 8ch;
		gap: 0.5rem 1rem;
		align-items: center;
	`

	return (
		<Fragment>
			<div>
				<div className={cx(cn.container.large, css`
					padding: ${theme.gutter.medium};
					display: grid;
					grid-template-columns: 1fr;
					gap: 2rem;
				`)}>
					<h1 className={css`margin: 0;`}>Leases</h1>
					<div className={css`
						display: grid;
						grid-template-columns: auto auto auto auto auto;
						gap: 1rem;
					`}>
						<CustomerSearch
							userId={location.query.userId}
							setUserId={setUserId}
							onDismiss={()=>{
								url.replace(location.pathname, {
									...location.query,
									page: null,
									userId: null,
								})
							}}
						/>
						<Input
							label='Boat'
							placeholder='Boat name...'
							type='text'
							name='q'
							value={location.query.q || ''}
							onChange={handleChange}
						/>
						<DateInput
							label='Start Date'
							name='start'
							value={location.query.start}
							onClick={handleChange}
						/>
						<DateInput
							label='End Date'
							name='end'
							value={location.query.end}
							onClick={handleChange}
						/>
						<Select label="Status"
							name='status'
							value={location.query.status || ''}
							onChange={handleChange}
							options={[
								{
									value: '',
								},
								{
									value: 'active',
								},
								{
									value: 'canceled',
								},
								{
									value: 'pending',
								},
								// { // todo: backend needs some mechanism for graduating leases to complete
								// 	value: 'complete',
								// },
							]}
						/>												
					</div>
					<div>
						<div className={cx(cnRow,css`
							margin-bottom: 1rem;
						`)}>
							<div>Lease ID</div>
							<div>Slip ID</div>
							<div>Name</div>
							<div>Email</div>
							<div>Phone</div>
							<div>Boat Name</div>
							<div>Status</div>
							<div>Start Date</div>
							<div>End Date</div>
							<div></div>
						</div>
						{
							leases.map(x => {
								let label = [];
								if (x.user.lastName) {
									if (x.user.firstName) {
										label.push(x.user.lastName+',')
									} else {
										label.push(x.user.lastName)
									}
								}
								if (x.user.firstName) {
									label.push(x.user.firstName);
								}
								if (!label.length && x.user.id) {
									label.push('Customer #'+x.user.id);
								}

								return (
									<div className={cx(cnRow, css`
										border-top: 1px solid ${theme.color.gray.lighter};
										padding: 0.5rem 0;
									`)}>
										<div>{x.id}</div>
										<div><a href={'/?slipId='+x.dockSlipId}>{x.dockSlipId}</a></div>
										<div className={cn.truncate}><a href={'/customers?id='+x.user.id}>{label.join(' ')}</a></div>
										<div className={cn.truncate}>{x?.user?.email && <a href={'email:'+x.user.email}>{x.user.email}</a>}</div>
										<div className={cn.truncate}>{x?.user?.phone && <a href={'tel:'+x.user.phone}>{x.user.phone}</a>}</div>
										<div className={cn.truncate}>{x.boatName}</div>
										<div className={css``}>{x.statusCode}</div>
										<div>{x.startTime && formatDate(x.startTime)}</div>
										<div>{x.endTime && formatDate(x.endTime)}</div>
										<div><button type='' onClick={()=> setEditLease(x)}>Edit</button></div>
									</div>
								)
							})
						}
					</div>
					<Pagination location={location} count={count} limit={limit}/>					
				</div>
			</div>
			{
				editLease && (
					<LeaseManage
						lease={editLease}
						onClose={()=> setEditLease(null)}
						onEditLease={()=>{
							setEditLease(null);
							search();
						}}
						onCancelLease={()=>{
							setEditLease(null);
							search();
						}}
					/>
				)
			}
		</Fragment>
	)
}

_Leases.pageData = async function ({}) {
	return {
		head: {
			title: 'Leases',
		},
	}
}
