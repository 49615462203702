import { h } from 'preact'
import { theme, css, cx, cn } from '../style'

export default function InfoItem({ label, children }) {
	return (
		<div>
			<div className={css`
				color: ${theme.color.gray.dark};
				font-size: ${theme.type.small};
				margin-bottom: 3px;
			`}>{label}</div>
			<div className={cn.bold}>{children}</div>
		</div>
	)
}