import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import svgTimes from '../embeds/times.svg';
import { useEffect, useRef, useState, useMemo } from 'preact/hooks'
import { createPortal } from 'preact/compat'

const stack = [];

function subscribe(setIsTopModal) {
	stack.push(setIsTopModal);

	function update() {
		stack.forEach((setIsTopModal,i) => {
			setIsTopModal((i + 1) === stack.length)
		})
	}

	update();

	return ()=> {
		const idx = stack.indexOf(setIsTopModal);
		stack.splice(idx,1);
		update();
	}
}

function useModals() {
	const [isTopModal, setIsTopModal] = useState(null);

	const cleanup = useMemo(()=> subscribe(setIsTopModal), []);

	useEffect(()=> cleanup, [])

	return isTopModal
}

export default function Modal({ onClose, focusCloseButton = true, children, width = 'auto', maxWidth = theme.break.large.max, alignTop = false }) {
	const _close = useRef<HTMLButtonElement>();
	const isTopModal = useModals();

	useEffect(() => {
		// manage focus to improve accessibility
		// remember the currently focused element
		const activeElement = document.activeElement as HTMLElement

		// focus the close button
		if (_close.current && focusCloseButton) {
			_close.current.focus()
		}

		return () => {
			// restore previously focused element
			if (activeElement && typeof activeElement.focus === 'function') {
				activeElement.focus()
			}
		}
	},[]);

	useEffect(() => {
		if (!isTopModal) return;

		const handleEsc = (e) => {
			if (e.keyCode === 27) {
				e.preventDefault()
				onClose()
			}
		}

		// close on Esc keypress
		document.addEventListener('keydown', handleEsc)

		return () => document.removeEventListener('keydown', handleEsc)
	}, [isTopModal])

	return createPortal((
		<div 
		style={{
			zIndex: isTopModal ? 2 : 1,
		}}
		className={css`
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: hsla(0,0%,20%,0.8);
			display: flex;
			padding: ${theme.gutter.medium};
			overflow: auto;
			overscroll-behavior: contain;
			z-index: ${theme.zIndex.modal};
		`}>
			<div className={css`
				border-radius: 10px;
				background: #fff;
				position: relative;
				box-shadow: 0 10px 20px rgba(0,0,0,0.2);
				margin-left: auto;
				margin-right: auto;
				height: fit-content;
			`} style={{
					width,
					maxWidth,
					marginTop: alignTop ? '2rem' : 'auto',
					marginBottom: alignTop ? null : 'auto',
				}}>
				<button
					type='button'
					onClick={onClose}
					aria-label='close modal'
					ref={_close}
					dangerouslySetInnerHTML={{__html: svgTimes}}
					className={cx(cn.unstyle, css`
						position: absolute;
						bottom: 100%;
						left: 100%;
						padding: 5px;
						opacity: 0.5;

						svg {
							width: 14px;
							display: block;
							fill: #fff;
						}

						&:hover {
							opacity: 1;
						}
					`)}
				/>
				{ children }
			</div>
		</div>
	), document.body)
}