import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import SlipCard from './SlipCard'
import { api } from '../api'
import { useState, useEffect, useCallback } from 'preact/hooks'
import ErrorMessage from './ErrorMessage'
import Section from './Section'
import { displayError } from '../errors'
import useDocks from '../use-docks'
import Select from './Select'
import useSlips from '../use-slips'

export default function LeaseSlipForm({ onSuccess, dockSlipId, leaseId }) {
	const [errorMsg, setErrorMsg] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [newSlip, setNewSlip] = useState(null);
	const [values, setValues] = useState({
		dockId: null,
		slipId: null,
	});
	const [docks] = useDocks();
	const { slips } = useSlips();
	const [currentSlip, setCurrentSlip] = useState(null);

	const fetchSlip = useCallback(async (slipId)=>{
		const res = await api({
			type: 'admin',
			query: `
				query(
					$id: Int!
				) {
					dockSlips(
						limit: 1
						offset: 0
						options: {
							id: $id
						}
					) {
						data {
							id
							dockId
							dockName
							code
							sizeType
							storageType
							statusCode
							coveredStatusCode
							maintenanceStatusCode
							width
							length
							depth
							standardRate
							lease {
								id
							}
						}
					}
				}
			`,
			variables: {
				id: slipId,
			}
		});
		
		if (res?.errors?.length) {
			throw new Error(res.errors[0].message);
		}

		return res?.data?.dockSlips?.data?.[0];
	}, [])

	useEffect(()=>{
		setNewSlip(null);

		if (!values.slipId) return;
		
		setIsBusy(true);
		setErrorMsg(null);

		fetchSlip(values.slipId).then(slip => {
			setIsBusy(false);
			setNewSlip(slip);
		}).catch(e => {
			setIsBusy(false);
			setErrorMsg(e.message);
		});
	}, [values.slipId]);

	useEffect(()=>{
		setCurrentSlip(null);
		fetchSlip(dockSlipId).then(slip => {
			setCurrentSlip(slip);
		}).catch(e => {
			setErrorMsg(e.message);
		});
	}, [dockSlipId]);

	async function handleSubmit(e) {
		e.preventDefault();

		if (!newSlip) return;

		setErrorMsg(null);
		setIsBusy(true);

		try {
			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$id: Int!
						$dockSlipId: Int!
					) {
						leaseSetNewDockSlip (
							id: $id
							dockSlipId: $dockSlipId
						)
					}
				`,
				variables: {
					id: leaseId,
					dockSlipId: newSlip.id,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			if (!res?.data?.leaseSetNewDockSlip) {
				throw new Error('Failed for unspecified reason');
			}

			onSuccess()
		} catch(e) {
			console.error(e);
			setErrorMsg(e.message);
			setIsBusy(false);
		}
	}

	function handleSelect(e) {
		setValues(values => {
			return {
				...values,
				[e.currentTarget.name]: Number(e.currentTarget.value),
			}
		});
	}

	// todo: check/display availability for time

	return (
		<form onSubmit={handleSubmit} className={css`
			width: 90vw;
			max-width: ${theme.break.large.max};
		`}>
			<div className={css`
				padding: ${theme.gutter.medium};
			`}>
				<Section title='Find Slip' cols={4}>
					<Select
						name='dockId'
						label='Dock'
						value={values.dockId || ''}
						onChange={handleSelect}
						options={[
							{
								value: '',
							},
							...(docks?.map(x => {
								return {
									value: x.id,
									label: x.name,
								}
							}) || [])
						]}/>
					<Select
						name='slipId'
						label='Slip'
						value={values.slipId || ''}
						onChange={handleSelect}
						options={[
							{
								value: '',
							},
							...(slips
								?.filter(x => values.dockId ? x.dockId === values.dockId : true)
								.sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1) // todo: this is garbage
								.map(x => {
								return {
									value: x.id,
									label: x.code,
								}
							}) || [])
						]}/>
				</Section>
			</div>
			<div className={css`
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: ${theme.gutter.medium};
				padding: ${theme.gutter.medium};
				border-top: 1px solid ${theme.color.gray.lighter};
			`}>
				{
					[
						['Current Slip', currentSlip],
						['New Slip', newSlip],
					].map(([title, slip], i)=> (
						<Section title={title} cols={1}>
							<div class={css`
								background: ${theme.color.gray.lightest};
								border-radius: ${theme.corner.medium};
								padding: ${theme.gutter.small};
								height: 100%;
							`}>
								{slip && <SlipCard slip={slip}/>}
							</div>
						</Section>
					))
				}
			</div>
			<div className={css`
				border-top: 1px solid ${theme.color.gray.lighter};
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: auto 1fr;
				gap: ${theme.gutter.small};
			`}>
				<button className={cx(cn.unstyle, cn.button.alt, isBusy && cn.busy)}>Save</button>
				<ErrorMessage message={errorMsg}/>
			</div>
		</form>
	)
}
