import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useEffect, useState, useRef, useCallback } from 'preact/hooks'
import SlipPopup from './SlipPopup'
import { api } from '../api'
import { displayError } from '../errors'
import Map from './Map'
import { setHeaderContent, useStore } from '../store'
import DateMenu from './DateMenu'
import Legend from './Legend'
import CustomerSearch from './CustomerSearch'
import SearchBar from './SearchBar'
import url from '../url'

function useLeases() { // todo: can this be combined with the other use-leases?
	const [leases, setLeases] = useState(null);
	const [isBusy, setIsBusy] = useState(false);

	const fetchLeases = useCallback(async ({ date, boatName = '', userId = '' }) => {
		const limit = 1000;
		const leases = {};

		setIsBusy(true);

		async function init(offset) {
			const endDate = new Date(date);
			endDate.setDate(endDate.getDate()+1);

			try {
				const options = {
					dates: {
						start: date.toISOString(),
						end: endDate.toISOString(),
					},
					excludeCanceled: true,
					boatName: boatName || null,
					userId: userId ? userId+'' : null, // todo: should not be string
				};

				const res = await api({
					type: 'admin',
					query: `
						query(
							$limit: Int!
							$offset: Int!
							$options: leaseOptions
						) {
							leases(
								limit: $limit
								offset: $offset
								options: $options
							) {
								count
								data {
									id
									dockSlipId
									statusCode
								}
							}
						}
					`,
					variables: {
						limit,
						offset,
						options,
					}
				});
				
				if (res?.errors?.length) {
					throw new Error(res.errors[0].message);
				}

				res?.data?.leases?.data.forEach(x => leases[x.dockSlipId] = x);

				if (res?.data?.leases?.count && (offset + limit < res.data.leases.count)) {
					init(offset + limit);
				} else {
					setLeases(leases);
					setIsBusy(false);
				}

			} catch (e) {
				setIsBusy(false);
				displayError(e);
			}
		}

		init(0);
	}, []);

	return {
		leases,
		fetchLeases,
		isBusy,
	};
}

function useSlips() {
	const [slips, setSlips] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const abortRef = useRef(null);

	const fetchSlips = useCallback(async () => {
		const limit = 1000;
		const slips = {};

		if (abortRef.current) {
			abortRef.current.abort();
		}

		setIsBusy(true);

		async function init(offset) {
			abortRef.current = new AbortController();

			try {
				const res = await api({
					signal: abortRef.current.signal,
					type: 'admin',
					query: `
						query(
							$limit: Int!
							$offset: Int!
						) {
							dockSlips(
								limit: $limit
								offset: $offset
							) {
								count
								data {
									id
									code
									statusCode
									maintenanceStatusCode
								}
							}
						}
					`,
					variables: {
						limit,
						offset,
					}
				});
				
				if (res?.errors?.length) {
					throw new Error(res.errors[0].message);
				}

				res?.data?.dockSlips?.data.forEach(x => slips[x.code] = x);

				if (res?.data?.dockSlips?.count && (offset + limit < res.data.dockSlips.count)) {
					init(offset + limit);
				} else {
					setSlips(slips);
					setIsBusy(false);
				}
			} catch (e) {
				if (e.name !== 'AbortError') {
					setIsBusy(false);
					displayError(e);
				}
			}
		}

		init(0);
	}, []);

	useEffect(()=>{
		fetchSlips();
	},[])

	return {
		slips,
		setSlips,
		refreshSlips: ()=> fetchSlips(),
		isBusy,
	};
}

export default function _Map({ page, location }) {
	const { slips, refreshSlips, setSlips, isBusy: isFetchingSlips } = useSlips();
	const { leases, fetchLeases, isBusy: isFetchingLeases } = useLeases();
	const date = useStore(s => s.date);
	const [boatName, setBoatName] = useState('');
	const [userId, setUserId] = useState('');

	function refreshLeases() {
		fetchLeases({ date, boatName, userId });
	}

	useEffect(()=>{
		refreshLeases();
	},[date, boatName, userId]);

	function boatSearch(e) {
		e.preventDefault();
	}

	let slipId = location.query.slipId ? Number(location.query.slipId) : null; // todo: API should be updated so everything uses slipCode
	let slipCode = location.query.slipCode || null;
	let leaseId = location.query.leaseId ? Number(location.query.leaseId) : null;

	if (!slipCode && slips && slipId) {
		const slip = Object.values(slips).find(({ id }) => id === slipId);

		if (slip) {
			slipCode = slip.code;
		}
	}

	useEffect(()=>{
		setHeaderContent(
			<div className={css`
				margin-left: auto;
				display: flex;
				gap: 1.5rem;
			`}>
				<SearchBar
					placeholder='Boat name'
					value={boatName}
					onInput={e => {
						setBoatName(e.currentTarget.value)
					}} onSubmit={boatSearch}/>
				<CustomerSearch setUserId={setUserId}/>
				<DateMenu/>
			</div>
		);
		return () => setHeaderContent(null);
	},[])

	function handleClose() {
		url.replace('/');
	}

	return (
		<Fragment>
			<div className={css`
				position: relative;
				overflow: hidden;
				height: 100%;

			`}>
				<Map slips={slips} leases={leases}/>
				{
					(isFetchingSlips || isFetchingLeases) && (
						<div className={css`
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: hsla(0,0%,0%,0.5);
						`}/>
					)
				}
				<Legend/>
			</div>
		{
			slipCode && (
				<SlipPopup 
					onClose={handleClose}
					onDeleteSlip={() => {
						const x = {...slips};
						delete x[slipCode];
						setSlips(x);
						handleClose();
					}}
					refreshSlips={refreshSlips}
					refreshLeases={refreshLeases}
					slipCode={slipCode}
					leaseId={leaseId}
				/>
			)
		}
		</Fragment>
	)
}

_Map.pageData = async function ({}) {
	return {
		head: {
			title: 'Map',
		},
	}
}
