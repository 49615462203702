import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import SlipCard from './SlipCard'
import SummaryBoat from './SummaryBoat'
import SummaryCustomer from './SummaryCustomer'
import SummaryContracts from './SummaryContracts'
import { useState } from 'preact/hooks'
import svgShip from '../embeds/ship.svg'
import svgAnchor from '../embeds/anchor.svg'
import svgUser from '../embeds/user.svg'
import svgContract from '../embeds/signature.svg'

export default function SlipOverview({ lease, slip }) {
	const [tabIndex, setTabIndex] = useState(0);

	let tabs = [
		{
			icon: svgAnchor,
			body: () => <SlipCard slip={slip}/>
		},
	];

	if (lease) {
		tabs = tabs.concat([
			{
				icon: svgShip,
				body: () => <SummaryBoat lease={lease}/>
			},
			{
				icon: svgUser,
				body: () => <SummaryCustomer lease={lease}/>
			},
			{
				icon: svgContract,
				body: () => <SummaryContracts lease={lease}/>
			},
		])
	}

	return (
		<div className={css`
			display: grid;
			grid-template-rows: auto 1fr;
		`}>
			<div className={css`
				display: grid;
				grid-template-columns: auto auto auto auto;
				box-shadow: inset 0 -2px 0 -1px ${theme.color.gray.lighter};
			`}>
				{
					tabs.map(({icon},i) => (
						<button key={i} dangerouslySetInnerHTML={{__html: icon}} onClick={()=> setTabIndex(i)} className={cx(cn.unstyle, css`
							padding: 20px;

							svg {
								display: block;
								margin: 0 auto;
								height: 24px;
								fill: ${theme.color.gray.medium};
							}
						`, i === tabIndex ? css`
							box-shadow: inset 0 -4px 0 -2px ${theme.color.link};

							svg {
								fill: ${theme.color.link};
							}
						` : css`
							&:hover svg {
								fill ${theme.color.link};
							}
						`)}/>
					))
				}
			</div>
			<div className={css`
				padding: ${theme.gutter.medium};
			`}>
				{
					tabs[tabIndex].body()
				}
			</div>
		</div>
	)
}
