import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import InfoGrid from './InfoGrid'
import InfoItem from './InfoItem'
import formatDate from '../format-date'

export default function SummaryContracts({ lease }) {
	return (
		<div>
			<InfoGrid items={[
				{
					label: 'Lease start date',
					value: lease.startTime ? formatDate(lease.startTime) : null,
				},
				{
					label: 'Lease end date',
					value: lease.endTime ? formatDate(lease.endTime) : null,
				},
			]}/>
			{
			// <div className={css`
			// 	border-top: 1px solid ${theme.color.gray.lighter};
			// 	padding-top: ${theme.gutter.medium};
			// 	margin-top: ${theme.gutter.medium};
			// `}>
			// 	<Info label='Invoice history'>
			// 		<div className={css`
			// 			display: grid;
			// 			gap: 5px;
			// 			grid-template-columns: auto 1fr;
			// 		`}>
			// 		{
			// 			[
			// 				{
			// 					date: '2021-06-01',
			// 					status: 'Due',
			// 				},
			// 				{
			// 					date: '2021-06-01',
			// 					status: 'Paid',
			// 				},
			// 				{
			// 					date: '2021-07-01',
			// 					status: 'Past Due',
			// 				},
			// 			].map(({ date, status }) => (
			// 				<Fragment>
			// 					<div>{date}</div>
			// 					<div style={{color: 
			// 						status === 'Due' ? theme.color.alert :
			// 						status === 'Paid' ? theme.color.green :
			// 						status === 'Past Due' ? theme.color.error : null
			// 					}}>{status}</div>
			// 				</Fragment>
			// 			))
			// 		}
			// 		</div>
			// 	</Info>
			// </div>
			}
			<div className={css`
				border-top: 1px solid ${theme.color.gray.lighter};
				padding-top: ${theme.gutter.medium};
				margin-top: ${theme.gutter.medium};
			`}>
				<InfoItem label='Notes'>{lease.note}</InfoItem>
			</div>
		</div>
	)
}
