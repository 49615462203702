import { h, Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import Boundary from './Boundary'
import { css } from '../style'
import Head from './Head'
import { api } from '../api'
import { useUsername } from '../api'
import Alerts from './Alerts';
import Header from './Header'
import SignIn from './SignIn'

export default function Root({ location, child, page, root }) {
	const username = useUsername();

	useEffect(() => {
			// dataLayer.push({
			// 	event: 'pageView',
			// 	title: page.head.title || '',
			// 	page: location.pathname+location.search,
			// })
	}, [page])

	return (
		<Fragment>
			<Head>
				<title>{page.head.title || ''}</title>
				<meta name='description' content={page.head.description || ''} />
				<link
					rel='canonical'
					href={
						window.location.origin +
						(page.head.canonical || location.pathname)
					}
				/>
				{page.head.noindex && <meta name='robots' content='noindex' />}
			</Head>
			{
				username ? (
					<div className={css`
						height: 100vh;
						display: grid;
						grid-template-rows: auto 1fr;
						min-width: 360px;
						background: #fff;
					`}>
						<Header/>
						<Boundary location={location}>
							<main className={css`
								display: grid;
								grid-template-columns: 1fr;
							`}>
								{h(child, { location, page })}
							</main>
						</Boundary>
					</div>
				) : <SignIn/>
			}
			<Alerts/>
		</Fragment>
	)
}

Root.rootData = async function ({}) {}
