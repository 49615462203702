import produce from 'immer'
import { useState, useCallback } from 'preact/hooks'

export function useImmer(initialValue: any) {
	const [val, updateValue] = useState(initialValue)

	return [
		val,
		useCallback((updater) => {
			updateValue(produce(updater))
		}, []),
	]
}
