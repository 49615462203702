import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import InfoGrid from './InfoGrid'

export default function SummaryCustomer({ lease }) {
	return (
		<div className={css`
			display: grid;
			grid-template-columns: 1fr;
			gap: ${theme.gutter.medium};
		`}>
			<InfoGrid items={[
				{
					label: 'First Name',
					value: lease?.user?.firstName,
				},
				{
					label: 'Last Name',
					value: lease?.user?.lastName,
				},
				{
					label: 'Phone',
					value: lease?.user?.phone ? <a href={'tel:'+lease.user.phone}>{lease.user.phone}</a> : null,
				},
				{
					label: 'Email',
					value: lease?.user?.email ? <a href={`mailto:${lease.user.email}`}>{lease.user.email}</a> : null,
				},
			]}/>
		</div>
	)
}
