import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useState, useEffect, useCallback, useMemo } from 'preact/hooks'
import Modal from './Modal'
import LeaseEdit from './LeaseEdit'
import LeaseReservationCheckout from './LeaseReservationCheckout'
import LeaseReservationFinish from './LeaseReservationFinish'

function getStep(lease) {
	// if (lease?.statusCode === 'pending') {
	// 	if (lease.purchaseOrderCode) {
	// 		return 'finish'
	// 	} else {
	// 		return 'checkout'
	// 	}
	// }
	return 'edit'
}

export default function LeaseCreate({
	lease,
	onClose,
	dockSlipId = null,
	onEditLease = null,
	onCreateLease = null,
	onCancelLease = null,
}) {
	const [step, setStep] = useState(getStep(lease));

	useEffect(()=>{
		setStep(getStep(lease))
	}, [lease?.statusCode])

	let body = null;

	if (step === 'edit') {
		body = (
			<LeaseEdit
				lease={lease}
				dockSlipId={dockSlipId}
				onEditLease={onEditLease}
				onCreateLease={onCreateLease}
				onCancelLease={onCancelLease}
				onContinue={()=> {
					setStep('checkout');
				}}
			/>
		)
	}

	if (step === 'checkout') {
		body = (
			<LeaseReservationCheckout
				arcUserId={lease.user.arcUserId}
				onSuccess={()=> {
					setStep('finish');
				}}
				/>
		);
	}

	if (step === 'finish') {
		body = (
			<LeaseReservationFinish
				lease={lease}
				onSuccess={()=>{

				}}
				/>
		)
	}

	return (
		<Modal onClose={onClose} alignTop={true}>
			<div className={css`
				width: 90vw;
				max-width: ${theme.break.medium.max};
			`}>
				{body}
			</div>
		</Modal>
	)
}
