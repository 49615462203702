import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import Select from './Select'
import Input from './Input'
import Textarea from './Textarea'
import { api } from '../api'
import { useState, useEffect, useCallback } from 'preact/hooks'
import ErrorMessage from './ErrorMessage'
import Section from './Section'
import { displayError } from '../errors'
import Modal from './Modal'
import LeaseSlipForm from './LeaseSlipForm'
import Confirm from './Confirm'
import PhotoUpload from './PhotoUpload'
import Label from './Label'

function UserSelect({ onSelect, readOnly }) {
	const [users, setUsers] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [search, setSearch] = useState('');

	const fetchUsers = useCallback(async (search) => { // todo: race condition
		if (!search) {
			setUsers(null);
			return;
		}

		try {
			const res = await api({
				type: 'admin',
				query: `
					query(
						$search: String!
					) {
						customers(
							limit: 12
							offset: 0
							options: {
								search: $search
							}
						) {
							data {
								user {
									id
									email
									firstName
									lastName
									phone
								}
							}
						}
					}
				`,
				variables: {
					search,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setIsBusy(false);
			setUsers(res.data.customers.data.map(x => x.user));
		} catch (e) {
			displayError(e);
		}
	}, []);

	useEffect(()=> {
		fetchUsers(search)
	}, [search])

	return (
		<div className={css`
			position: relative;
		`}>
			<Input
				readOnly={readOnly}
				onInput={e => setSearch(e.currentTarget.value)}
				value={readOnly ? '' : search}
				placeholder='Search by name or email'
				autoFocus={true}
			/>
			{
				users && !readOnly && (
					<div className={css`
						position: absolute;
						top: 100%;
						left: 0;
						box-shadow: 0 5px 16px rgb(0 0 0 / 25%);
						display: grid;
						grid-template-columns: 1fr;
						z-index: 1;
						background: #fff;
						border-bottom-right-radius: ${theme.corner.medium};
						border-bottom-left-radius: ${theme.corner.medium};
					`}>
						{
							users.length > 0 ? users.map(user => (
								<button
									type='button'
									className={cx(cn.unstyle, css`
										padding: 0.5em 1em;
										white-space: nowrap;

										&:hover {
											background: ${theme.color.gray.lighter};
										}
									`)}
									onClick={()=> {
										onSelect(user)
										setSearch('');
									}}
								>
									{user.firstName} {user.lastName} ({user.email})
								</button>
							)) : (
								<div className={css`
									padding: 1em;
									white-space: nowrap;
									color: color: ${theme.color.gray.medium};
								`}>No users found for "{search}"</div>
							)
						}
					</div>
				)
			}
		</div>
	)
}


function CancelLeaseButton({ leaseId, onCancelLease }) {
	const [isBusy, setIsBusy] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	async function handleCancel() {
		setErrorMessage(null);
		setIsBusy(true);

		try {
			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$id: Int!
					) {
						leaseReservationCancel (
							id: $id
						)
					}
				`,
				variables: {
					id: leaseId,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setIsBusy(false);
			onCancelLease()
		} catch(e) {
			console.error(e);
			setErrorMessage(e.message);
			setIsBusy(false);
		}
	}

	return (
		<Fragment>
			<button type='button' onClick={()=> setShowConfirm(true)}>Cancel</button>
			{
				showConfirm && (
					<Confirm
						isBusy={isBusy}
						onCancel={()=> setShowConfirm(false)}
						onConfirm={handleCancel}
						errorMessage={errorMessage}
					>
						Are you sure you want to cancel this reservation?
					</Confirm>
				)
			}
		</Fragment>
	)
}

function LeaseForm({
	title,
	onSubmit,
	values,
	setValues,
	errorMessage,
	isBusy,
	children = null,
}) {
	const [photo, setPhoto] = useState({
		base64: null,
		fileName: null,
	});

	const [customerType, setCustomerType] = useState('existingCustomer');

	const handleInput = useCallback((e) => {
		setValues(values => {
			return {
				...values,
				[e.currentTarget.name]: e.currentTarget.value,
			}
		});
	}, []);

	const handleCustomerType = useCallback((e) => {
		setCustomerType(e.currentTarget.value);
		setValues(values => ({
			...values,
			userId: '',
			customerEmail: '',
			customerPhone: '',
			customerFirstName: '',
			customerLastName: '',
		}))
	}, []);

	return (
		<form onSubmit={onSubmit}>
			<div className={css`
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: 1fr;
				gap: ${theme.gutter.medium};
			`}>
				<div className={cn.h3}>{title}</div>
				{
					children
				}
								<Section title='Customer'>
					<div className={css`
						grid-column: 1/-1;
					`}>
						<div className={css`
							display: grid;
							grid-template-columns: auto 1fr;
							gap: 1rem;
							margin-bottom: 0.5rem;
						`}>
						{
							[
								{
									label: 'Existing Customer',
									value: 'existingCustomer',
								},
								{
									label: 'New Customer',
									value: 'newCustomer',
								},
							].map(({ label, value }) => (
								<label className={css`
									display: grid;
									grid-template-columns: auto 1fr;
									gap: 0.5em;
								`}>
									<input
										type='radio'
										name='customerType'
										required
										value={value}
										checked={value === customerType}
										onInput={handleCustomerType}/>
									<div>{label}</div>
								</label>
							))
						}
						</div>
						<UserSelect readOnly={customerType === 'newCustomer'} onSelect={user =>{
							setValues(values => ({
								...values,
								userId: user.id,
								customerEmail: user.email,
								customerPhone: user.phone,
								customerFirstName: user.firstName,
								customerLastName: user.lastName,
							}))
						}}/>
					</div>
					<Input
						label='Email'
						name='customerEmail'
						value={values.customerEmail}
						onInput={handleInput}
						readOnly={customerType === 'existingCustomer'}
						required={customerType === 'newCustomer'}
						type='email'
						/>
					<Input
						label='First Name'
						name='customerFirstName'
						value={values.customerFirstName}
						onInput={handleInput}
						readOnly={customerType === 'existingCustomer'}
						/>
					<Input
						label='Last Name'
						name='customerLastName'
						value={values.customerLastName}
						onInput={handleInput}
						readOnly={customerType === 'existingCustomer'}
						/>
					<Input
						label='Phone'
						name='customerPhone'
						value={values.customerPhone}
						onInput={handleInput}
						readOnly={customerType === 'existingCustomer'}
						type='tel'
						/>
				</Section>
				<Section title='Insurance'>
					<Input
						name='insuredName'
						label="Name of Insured Person"
						value={values.insuredName}
						onInput={handleInput}
						required
						/>
					<Input
						name='insurerName'
						label="Insurance Company Name"
						value={values.insurerName}
						onInput={handleInput}
						required
						/>
					<Input
						name='insurerPhone'
						label="Insurance Company Phone"
						value={values.insurerPhone}
						onInput={handleInput}
						required
						type='tel'
						/>
					<Input
						name='insurerPolicyNumber'
						label='Policy Number'
						value={values.insurerPolicyNumber}
						onInput={handleInput}
						required
						/>
				</Section>
				<Section title='Boat'>
					<Input
						name='boatName'
						label='Name'
						value={values.boatName}
						onInput={handleInput}
						required
						/>
					<Input
						name='boatType'
						label='Type'
						value={values.boatType}
						onInput={handleInput}
						required
						/>
					<Input
						name='boatMake'
						label='Make'
						value={values.boatMake}
						onInput={handleInput}
						required
						/>
					<Input
						name='boatYear'
						label='Year'
						value={values.boatYear}
						onInput={handleInput}
						required
						/>
					<Input
						name='boatNumber'
						label='Number'
						value={values.boatNumber}
						onInput={handleInput}
						required
						/>
					<Input
						name='boatLength'
						label='Length'
						value={values.boatLength}
						onInput={handleInput}
						required
						/>
					<div className={css`
						grid-column: 1/-1;
					`}>
						<Label label='Photo'>
							<PhotoUpload
								base64={photo.base64}
								fileName={photo.fileName}
								onSelect={({ base64, fileName}) => {
									setPhoto({
										base64,
										fileName,
									})
									setValues(values => ({
										...values,
										boatUrl: '',
									}))
								}}
								onDelete={() => {
									setPhoto({
										base64: null,
										fileName: null,
									})
									setValues(values => ({
										...values,
										boatUrl: '',
									}))
								}}
							/>
						</Label>
					</div>
				</Section>
				<Section title='Notes'>
					<div className={css`
						grid-column: 1 / -1;
					`}>
					<Textarea
						name='note'
						value={values.note}
						onInput={handleInput}
						/>
					</div>
				</Section>
			</div>
			<div className={css`
				border-top: 1px solid ${theme.color.gray.lighter};
				padding: ${theme.gutter.medium};
				display: grid;
				grid-template-columns: auto 1fr;
				gap: ${theme.gutter.small};
			`}>
				<button className={cx(cn.unstyle, cn.button.alt, isBusy && cn.busy)}>Save</button>
				<ErrorMessage message={errorMessage}/>
			</div>
		</form>
	)
}

function ChangeSlipButton({ lease, onEditLease }) {
	const [isEditLeaseSlip, setIsEditLeaseSlip] = useState(false);

	return (
		<Fragment>
			<Input
				label='Slip ID'
				value={lease.dockSlipId} // todo: use slip code? / standardize APIs on slip code?
				readOnly
			>
				<button type='button' onClick={()=> setIsEditLeaseSlip(true)}>Edit</button>
			</Input>
			{
				isEditLeaseSlip && (
					<Modal onClose={()=> setIsEditLeaseSlip(false)} alignTop={true}>
						<LeaseSlipForm leaseId={lease.id} dockSlipId={lease.dockSlipId} onSuccess={()=>{
							setIsEditLeaseSlip(false)
							onEditLease();
						}}/>
					</Modal>
				)
			}
		</Fragment>
	)
}

function LeaseModify({
	lease,
	onEditLease,
	onContinue,
	onCancelLease,
}) {
	const [errorMessage, setErrorMessage] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [values, setValues] = useState(()=> ({
		boatName: lease.boatName,
		boatType: lease.boatType,
		boatMake: lease.boatMake,
		boatYear: lease.boatYear,
		boatNumber: lease.boatNumber,
		boatLength: lease.boatLength,
		boatUrl: lease.boatUrl,
		insurerName: lease.insurerName,
		insuredName: lease.insuredName,
		insurerPolicyNumber: lease.insurerPolicyNumber,
		insurerPhone: lease.insurerPhone,
		customerEmail: lease.user.email,
		customerPhone: lease.user.phone,
		customerFirstName: lease.user.firstName,
		customerLastName: lease.user.lastName,
		note: lease.note,
		userId: lease.user.id,
		photos: [...lease.photos], // todo
	}));

	async function handleSubmit(e) {
		e.preventDefault();

		setErrorMessage(null);
		setIsBusy(true);

		try {
			const input = {
				note: values.note,
				boat: {
					name: values.boatName,
					type: values.boatType,
					make: values.boatMake,
					year: values.boatYear,
					number: values.boatNumber,
					length: values.boatLength,
					url: values.boatUrl,
				},
				insurer: {
					insurerName: values.insurerName,
					insuredName: values.insuredName,
					policyNumber: values.insurerPolicyNumber,
					phone: values.insurerPhone,
				}
			} as any;

			if (values.userId) {
				input.userId = values.userId;
			} else {
				input.customer = {
					email: values.customerEmail,
					phone: values.customerPhone,
					firstName: values.customerFirstName,
					lastName: values.customerLastName,
				}
			}

			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$id: Int!
						$input: leaseReservationUpdateInput!
					) {
						leaseReservationUpdate (
							id: $id
							input: $input
						)
					}
				`,
				variables: {
					id: lease.id,
					input,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			onEditLease()
		} catch(e) {
			console.error(e);
			setErrorMessage(e.message);
			setIsBusy(false);
		}
	}

	return (
		<LeaseForm
			title='Edit Lease'
			values={values}
			setValues={setValues}
			onSubmit={handleSubmit}
			isBusy={isBusy}
			errorMessage={errorMessage}
		>
			<Section cols={2}>
				<ChangeSlipButton lease={lease} onEditLease={onEditLease}/>
				<Input
					label='Lease Status'
					value={lease.statusCode}
					readOnly
				>
					{
						lease.statusCode === 'pending' && (
							<CancelLeaseButton leaseId={lease.id} onCancelLease={onCancelLease}/>
						)
					}
					{
						lease.statusCode === 'pending' && (
							<button type='button' onClick={onContinue}>Continue</button>
						)
					}
				</Input>
			</Section>
		</LeaseForm>
	)
}

function LeaseCreate({ dockSlipId, onCreateLease }) {
	const [errorMessage, setErrorMessage] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [values, setValues] = useState({
		boatName: '',
		boatType: '',
		boatMake: '',
		boatYear: '',
		boatNumber: '',
		boatLength: '',
		boatUrl: '',
		insurerName: '',
		insuredName: '',
		insurerPolicyNumber: '',
		insurerPhone: '',
		customerEmail: '',
		customerPhone: '',
		customerFirstName: '',
		customerLastName: '',
		note: '',
		userId: '',
	});

	async function handleSubmit(e) {
		e.preventDefault();

		setErrorMessage(null);
		setIsBusy(true);

		const input = {
			dockSlipId,
			note: values.note,
			boat: {
				name: values.boatName,
				type: values.boatType,
				make: values.boatMake,
				year: values.boatYear,
				number: values.boatNumber,
				length: values.boatLength,
				url: values.boatUrl,
			},
			insurer: {
				insurerName: values.insurerName,
				insuredName: values.insuredName,
				policyNumber: values.insurerPolicyNumber,
				phone: values.insurerPhone,
			}
		} as any;

		if (values.userId) {
			input.userId = values.userId;
		} else {
			input.customer = {
				email: values.customerEmail,
				phone: values.customerPhone,
				firstName: values.customerFirstName,
				lastName: values.customerLastName,
			}
		}

		try {
			const res = await api({
				type: 'admin',
				query: `
					mutation(
						$marinaCode: String!
						$input: leaseReservationStartInput!
					) {
						leaseReservationStart (
							marinaCode: $marinaCode
							input: $input
						) {
							id
						}
					}
				`,
				variables: {
					marinaCode: _env.MARINA_CODE,
					input,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			onCreateLease(res.data.leaseReservationStart.id);
		} catch(e) {
			console.error(e);
			setErrorMessage(e.message);
			setIsBusy(false);
		}
	}

	return (
		<LeaseForm
			title='Create Lease'
			values={values}
			setValues={setValues}
			onSubmit={handleSubmit}
			isBusy={isBusy}
			errorMessage={errorMessage}
		>
		</LeaseForm>
	)
}

export default function LeaseEdit({
	lease = null,
	dockSlipId = null,
	onEditLease,
	onCreateLease = null,
	onContinue,
	onCancelLease,
}) {
	return lease ? (
		<LeaseModify
			lease={lease}
			onEditLease={onEditLease}
			onContinue={onContinue}
			onCancelLease={onCancelLease}
		/>
	) : (
		<LeaseCreate dockSlipId={dockSlipId} onCreateLease={onCreateLease}/>
	)
}
