import _Map from './components/_Map'
import _Dashboard from './components/_Dashboard'
import _Timeline from './components/_Timeline'
import _Customers from './components/_Customers'
import _Leases from './components/_Leases'
import _Error404 from './components/_Error404'

/*
	Each route has two parts: a pathname regex and function that returns a component.
	Routes return a function, rather than the component directly, in order to support asynchronous imports.
	Async components are loaded on demand, rather than being part of the main JS bundle.

	Example:
		Bundled: ['/', ()=>Home]
		Asynchronous: ['/', ()=>import('./components/Home')]

	The splitting/chunking/loading is handled automatically by Webpack during the build process.
	Determining what gets bundled vs loaded async is a personal judgement call.
*/

// const stateSlug = `(?<stateSlug>[a-z-*]*)`
// const page = `(?<page>[1-9][0-9]*)`


const routes = [
	['/', () => _Map],
	['/dash', () => _Dashboard],
	['/timeline', () => _Timeline],
	['/customers', () => _Customers],
	['/leases', () => _Leases],
	// Anything remaining is 404
	[/.+/, () => _Error404],
].map(([val, comp]) => {
	return [
		val instanceof RegExp ? val : new RegExp(`^${(val as string).replace(/\//g, '/')}$`),
		comp,
	]
})

export default function (pathname) {
	let ret = null

	routes.some(([reg, componentFunction]) => {
		const matches = (reg as RegExp).exec(pathname)

		if (matches) {
			// Allowing for short-circuit/opt-out by explicitly matching a route,
			// but not associating it with a component
			if (!componentFunction) {
				return true
			}
			ret = [componentFunction, matches.groups]
			return true
		}
	})

	return ret
}
