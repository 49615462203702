import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import Modal from './Modal'
import { useEffect, useState, useRef, useCallback } from 'preact/hooks'
import { api } from '../api'
import { displayError } from '../errors'
import ErrorMessage from './ErrorMessage'
import SlipEdit from './SlipEdit'
import LeaseManage from './LeaseManage'
import SlipOverview from './SlipOverview'

function useLease({ id }) {
	const [lease, setLease] = useState(null);
	const [error, setError] = useState(null);
	const [isBusy, setIsBusy] = useState(false);

	const fetchLease = useCallback(async ({ id }) => {
		setIsBusy(true);
		setError(null);

		if (!id) {
			setLease(null);
			return;
		}

		try {
			const res = await api({
				type: 'admin',
				query: `
					query(
						$id: Int!
					) {
						leases(
							limit: 1
							offset: 0
							options: {
								id: $id
							}
						) {
							data {
								id
								dockSlipId
								purchaseOrderCode
								startTime
								endTime
								boatName
								boatType
								boatMake
								boatYear
								boatNumber
								boatLength
								insurerName
								insuredName
								insurerPolicyNumber
								insurerPhone
								photos
								note
								statusCode
								user {
									id
									arcUserId
									type
									username
									email
									firstName
									lastName
									phone
								}
							}
						}
					}
				`,
				variables: {
					id,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setIsBusy(false);
			setLease(res?.data?.leases?.data?.[0]);
		} catch (e) {
			setIsBusy(false);
			setError(e);
		}
	}, [])

	useEffect(()=> {
		fetchLease({ id })
	}, [id]);

	return {
		lease,
		error,
		isBusy,
		setLease,
		fetchLease,
		refreshLease: ()=> fetchLease({ id }),
	};
}

function EditLeaseButton({ dockSlipId, lease, onCancelLease, onCreateLease, onEditLease }) {
	const [isEdit, setIsEdit] = useState(false);

	return (
		<Fragment>
			<button onClick={() => setIsEdit(true)}>{lease?.id ? 'Edit Lease' : 'Create Lease'}</button>
			{
				isEdit && (
					<LeaseManage
						lease={lease}
						dockSlipId={dockSlipId}
						onClose={()=> setIsEdit(false)}
						onCreateLease={(id)=> {
							onCreateLease(id);
							setIsEdit(false);
						}}
						onEditLease={()=> {
							onEditLease();
							setIsEdit(false);
						}}
						onCancelLease={()=>{
							onCancelLease();
							setIsEdit(false);
						}}
					/>
				)
			}
		</Fragment>
	)
}

function EditSlipButton({ slip, onSuccess, onDeleteSlip }) {
	const [isEdit, setIsEdit] = useState(false);
	const handleClose = useCallback(()=> setIsEdit(false), []);

	return (
		<Fragment>
			<button type='button' onClick={() => setIsEdit(true)}>Edit Slip</button>
			{
				isEdit && (
					<Modal onClose={()=> setIsEdit(false)} alignTop={true}>
						<div className={css`
							min-width: 500px;
						`}>
							<SlipEdit slipCode={slip.code} slip={slip} onSuccess={async ()=> {
								await onSuccess();
								handleClose();
							}} onDeleteSlip={onDeleteSlip}/>
						</div>
					</Modal>
				)
			}
		</Fragment>
	)
}

export default function SlipPopup({ onClose, slipCode, leaseId, refreshSlips, refreshLeases, onDeleteSlip }) {
	const [slip, setSlip] = useState(null);
	const [isBusy, setIsBusy] = useState(true);
	const [errorMsg, setErrorMsg] = useState(null);
	const { lease, error: leaseError, refreshLease, setLease, fetchLease } = useLease({ id: leaseId });

	useEffect(()=>{
		if (leaseError) {
			return displayError(leaseError);
		}
	}, [leaseError])

	const fetchSlip = useCallback(async (slipCode)=>{
		setIsBusy(true);

		try {
			const res = await api({
				type: 'admin',
				query: `
					query(
						$code: String!
					) {
						dockSlips(
							limit: 1
							offset: 0
							options: {
								code: $code
							}
						) {
							data {
								id
								dockId
								dockName
								code
								sizeType
								storageType
								statusCode
								coveredStatusCode
								maintenanceStatusCode
								rotate
								xCoord
								yCoord
								width
								length
								height
								depth
								standardRate
							}
						}
					}
				`,
				variables: {
					code: slipCode,
				}
			});
			
			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}

			setIsBusy(false);
			setSlip(res?.data?.dockSlips?.data?.[0]);
		} catch (e) {
			setIsBusy(false);
			// setErrorMsg(e.message); // todo
		}
	}, [])

	useEffect(()=>{
		fetchSlip(slipCode);
	}, [slipCode])

	const handleSlipEdit = useCallback(async slipCode => {
		return await Promise.all([
			fetchSlip(slipCode),
			refreshSlips(),
		])
	}, []);

	return (
		<Modal onClose={onClose} alignTop={true}>
			<div className={css`
				min-width: 500px;
				min-height: 380px;
				display: grid;
			`}>
			{
				isBusy ? (
					<div className={cn.busy}/>
				) : errorMsg ? (
					<div className={css`
						margin: auto;
						padding: ${theme.gutter.medium};
					`}>
						<ErrorMessage message={errorMsg}/>
					</div>
				) : !slip ? (
					<SlipEdit slipCode={slipCode} onSuccess={handleSlipEdit}/>
				) : (
					<div className={css`
						display: grid;
						grid-template-rows: 1fr auto;
					`}>
						<SlipOverview slip={slip} lease={lease}/>
						<div className={css`
							border-top: 1px solid ${theme.color.gray.lighter};
							padding: ${theme.gutter.medium};
							display: flex;
							gap: ${theme.gutter.small};
						`}>
							<EditSlipButton slip={slip} onSuccess={handleSlipEdit} onDeleteSlip={onDeleteSlip}/>
							<EditLeaseButton
								dockSlipId={slip.id}
								lease={lease} 
								onEditLease={()=> {
									refreshLease();
									refreshLeases();
								}}
								onCreateLease={(id)=> {
									fetchLease({ id });
									refreshLeases();
								}}
								onCancelLease={()=>{
									setLease(null);
									refreshLeases();
								}}
							/>
						</div>
					</div>
				)
			}
			</div>
		</Modal>
	)
}
