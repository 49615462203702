import { h, Fragment } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useRef, useEffect, useState, useCallback } from 'preact/hooks'
import { api } from '../api'
import { displayError } from '../errors'
import Block from './Block'
import EditCustomer from './EditCustomer'
import Select from './Select'
import svgDesc from '../embeds/descending-sort.svg'
import svgAsc from '../embeds/ascending-sort.svg'
import Input from './Input'
import url from '../url'
import Pagination from './Pagination'

const limit = 50;

export default function _Customers({ location }) {
	const [customers, setCustomers] = useState([]);
	const [count, setCount] = useState(null);
	const [editCustomer, setEditCustomer] = useState(null);

	const search = useCallback(async (query)=>{
		const options = {} as any;
		const sort = {} as any;
		const page = Math.max(query.page ? Number(query.page) : 1, 1);
		const offset = page * limit - limit;

		if (query.q) {
			options.search = query.q;
		}

		if (query.id) {
			options.id = query.id;
		}

		if (query.sortType) {	
			if(query.order){
				sort[query.sortType] = query.order;
			}			
		}

		try {
			const res = await api({
				type: 'admin',
				query: `
					query(
						$limit: Int!
						$offset: Int!
						$options: customerOptions
						$sort: customerOptionSort 
					) {
						customers(
							limit: $limit
							offset: $offset
							options: $options 	
							sort: $sort 				
						) {
							count
							data {
								user {
									id
									email
									firstName
									lastName
									phone
								}
							}
						}
					}
				`,
				variables: {							
					offset,
					limit,
					options,
					sort,
				}
			});

			if (res?.errors?.length) {
				throw new Error(res.errors[0].message);
			}			

			setCustomers(res.data.customers.data);
			setCount(res.data.customers.count);
		} catch (e) {
			displayError(e);
		}
	}, [])

	useEffect(()=>{		
		search(location.query);
	}, [location.query])

	function handleChange(e) {
		url.replace(location.pathname, {
			...location.query,
			page: null,
			[e.currentTarget.name]: e.currentTarget.value || null,
		})
	}

	const order = !(location.query.order) || location.query.order == 'asc' ? 'desc' : 'asc';	

	return (
		<Fragment>
			<div>
				<div className={cx(cn.container.large, css`
					padding: ${theme.gutter.medium};
					display: grid;
					grid-template-columns: 1fr;
					gap: 2rem;
				`)}>
					<h1 className={css`margin: 0;`}>Customers</h1>
					{
						!location.query.id && (
							<div className={css`
								display: grid;
								grid-template-columns: 1fr auto;	
								gap: 1rem;
							`}>
								<Input
									label='Search'
									placeholder='Search by name or email'
									type='text'
									name='q'
									value={location.query.q || ''}
									onChange={handleChange}
								/>	
								<Select label="Sort"
									name='sortType'
									value={location.query.sortType || ''}
									onChange={handleChange}
									options={[
										{
											value: '',
										},
										{
											value: 'id',
										},
										{
											value: 'email',
										},
									]}
								>
									<button 
										name='order' 
										value={order} 
										onClick={handleChange}
										disabled={!(location.query.sortType) ? true : false}
										className={cx(cn.unstyle, cn.button.normal, css`
											display: flex;
											align-items: center;
											padding: 0 0.7em;

											svg {
												width: 1.1em;
												fill: currentColor;
											}
										`)} dangerouslySetInnerHTML={{__html: order == 'desc' ? svgDesc : svgAsc}}/>		
								</Select>
							</div>
						)							
					}	
					{
						location.query.id && (
							<div className={css`
								text-align: center;
							`}>
								<a href='/customers'>Show All</a>
							</div>								
						)
					}										
					<div className={css`
						display: grid;
						grid-template-columns: 1fr;
						gap: 0.5rem;
					`}>
						{
							customers.map(x => {
								return (
									<div className={css`

										& + & {
											border-top: 1px solid ${theme.color.gray.lighter};
											padding-top: 0.5rem;
										}

										display: grid;
										grid-template-columns: 1fr 1fr 1fr auto auto;
										gap: 1rem;
										align-items: center;
									`}>
										<div className={cx(cn.truncate)}>{x.user.firstName} {x.user.lastName}</div>
										<div className={cx(cn.truncate)}>{x.user.email}</div>
										<div className={cx(cn.truncate)}>{x.user.phone}</div>
										<div><a href={'/leases?userId='+x.user.id}>Leases</a></div>
										<div><button type='' onClick={()=> setEditCustomer(x)}>Edit</button></div>
									</div>
								)
							})
						}
					</div>
					<Pagination location={location} count={count} limit={limit} />
				</div>
			</div>
			{
				editCustomer && (
					<EditCustomer
						customer={editCustomer}
						onSuccess={()=>{}}
						onClose={()=> setEditCustomer(null)}
					/>
				)
			}
		</Fragment>
	)
}

_Customers.pageData = async function ({}) {
	return {
		head: {
			title: 'Customers',
		},
	}
}
