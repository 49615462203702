import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { useEffect, useState, useRef, useCallback } from 'preact/hooks'
// import { displayError } from '../errors'
// import { api } from '../api'
import monthNames from '../month-names'
import dayNames from '../day-names'

const cnHeader = css`
	background: ${theme.color.gray.lighter};
	padding: 0.7em 1em;
	display: flex;
	justify-content: center;
`;

const data = [
	{
		slip: 345,
		schedule: [
			{
				start: '2021-10-09',
				end: '2021-10-30',
				renewal: '2021-10-11',
				userName: 'John Doe',
				boatNumber: '7RE78690',
				status: 'leased',
			},
		],
	},
	{
		slip: 346,
		schedule: [
			{
				start: '2021-10-01',
				end: '2021-10-13',
				status: 'available',
			},
			{
				start: '2021-10-14',
				end: '2021-10-30',
				renewal: '2022-10-11',
				userName: 'John Doe',
				boatNumber: '7RE78690',
				status: 'leased',
			},
		],
	},
	{
		slip: 347,
		schedule: [
			{
				start: '2021-10-09',
				end: '2021-10-30',
				renewal: '2021-10-11',
				userName: 'John Doe',
				boatNumber: '7RE78690',
				status: 'leased',
			},
		],
	},
];

export default function _Timeline({ page, location }) {
	const dates = [];

	for (var i = 0; i < 7; i++) {
		let d = new Date();
		d.setDate(d.getDate() + i);
		dates.push(d);
	}

	return (
		<div className={css`
			display: grid;
			grid-template-columns: auto repeat(7, 1fr);
			gap: 1px;
			background: ${theme.color.gray.light};
		`}>
			<div className={cnHeader}/>
			{
				dates.map(d => {
					return (
						<div className={cnHeader}>
							<div className={cn.bold}>{dayNames[d.getDay()]}</div>
							<div className={cx(cn.bold, css`
								margin-left: 0.7em;
								color: ${theme.color.gray.medium};
							`)}>{d.getDate()} {monthNames[d.getMonth()].slice(0,3)}</div>
						</div>
					)
				})
			}
		</div>
	)
}

_Timeline.pageData = async function ({}) {
	return {
		head: {
			title: 'Timeline',
		},
	}
}
