import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import Input from './Input'
import DatePicker from './DatePicker'
import { useEffect, useCallback, useState, useRef } from 'preact/hooks'
import svgCalendar from '../embeds/calendar-alt.svg'

export default function DateInput({
	label = null,
	name = null,
	required = null,
	value = '',
	onClick = null,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const toggleRef = useRef<HTMLDivElement>();
	const buttonRef = useRef<HTMLButtonElement>();

	useEffect(()=>{
		if (!isOpen) return;

		function escape(e) {	
			if ((!toggleRef.current || !toggleRef.current.contains(e.target)) && e.target !== buttonRef) {
				setIsOpen(false);
			}
		}

		window.addEventListener('click', escape);

		return ()=> window.removeEventListener('click', escape);
	}, [isOpen])

	const handleClick = e => {
		setIsOpen(false);
		if (onClick) {
			onClick(e);
		}
	}

	let formatted = '';
	if (value) {
		formatted = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(new Date(Number(value)))
	}

	return (
		<div className={css`position: relative;`}>
			<Input
				label={label}
				readOnly={true}
				useReadOnlyStyle={false}
				required={required}
				value={formatted}
				onClick={()=> setIsOpen(!isOpen)}
			>
				<button 
					ref={buttonRef}
					onClick={()=> setIsOpen(!isOpen)}
					className={cx(cn.unstyle, cn.button.normal, css`
						display: flex;
						align-items: center;
						padding: 0 0.7em;

						svg {
							width: 1em;
							fill: currentColor;
						}
					`)} dangerouslySetInnerHTML={{__html: svgCalendar}}/>
			</Input>
			{
				isOpen && (
					<div ref={toggleRef} className={css`
						position: absolute;
						top: 100%;
						left: 0;
						z-index: ${theme.zIndex.datePicker};
						background: #fff;
						box-shadow: 0 5px 20px rgb(0 0 0 / 15%);
						padding: ${theme.gutter.medium};
						border-radius: ${theme.corner.medium};
					`}>
						<DatePicker name={name} onClick={handleClick} value={value}/>
					</div>
				)
			}
		</div>
	)
}
