import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import { removeError } from '../errors'
import svgTimes from '../embeds/times.svg';

export default function ErrorMessage({ message, dismissable = false }) {
	return message && (
		<div className={css`
			border-radius: ${theme.corner.small};
			color: ${theme.color.error};
			background: ${theme.color.lightError};
			padding: 0.8em 1.6em;
			text-align: center;
			display: grid;
			grid-template-columns: 1fr auto;
			gap: 10px;
			align-items: center;
		`}>
			<div>{message}</div>
			{
				dismissable && (
					<button type='button' label='dismiss error' className={cx(cn.unstyle, css`
						display: flex;
						align-items: center;

						svg {
							width: 1em;
						}
					`)} onClick={()=> removeError(message)} dangerouslySetInnerHTML={{__html: svgTimes}}/>
				)
			}
		</div>
	)
}
