import { getToken } from './api'

let id = 0;
export default async function arc(request) {
	const res = await fetch(_env.ARC, {
		method: 'POST',
		headers: {
			'Content-Type': 'text/plain',
		},
		body: JSON.stringify({
			format: "json",
			version: 1,
			id: ++id,
			requests: [request],
			accessToken: await getToken(),
		}),
	})
	const json = await res.json();
	return json?.responses?.[0];
}