import { h, Component } from 'preact'
import { theme, css } from '../style'

export default class Boundry extends Component<{ location: any }> {
	state = { error: false, location: this.props.location }

	static getDerivedStateFromError(error) {
		console.error(error)
		return { error }
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			location: nextProps.location,
			error:
				nextProps.location !== prevState.location ? false : prevState.error,
		}
	}

	render() {
		if (this.state.error) {
			return (
				<div
					className={css`
						padding: 4rem 2rem;
						text-align: center;
					`}
				>
					Sorry, this page encountered an error.
				</div>
			)
		}

		return this.props.children
	}
}
