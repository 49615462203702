if (process.env.NODE_ENV==='development') {
  // Must use require here as import statements are only allowed
  // to exist at top-level.
  require('preact/debug');
}
import { h } from 'preact'
import { render } from 'preact'
import localLink from 'local-link'
import routes from './routes'
import url from './url'
import Root from './components/Root'
import _Error404 from './components/_Error404'

// Intercept and convert normal navigation via anchor elements to SPA navigation
window.addEventListener('click', (e) => {
	const anchor = localLink(e)

	if (!anchor) return

	// IE screws up all kinds of location info when accessing it directly off the in-DOM anchor element.
	// However, IE does report the full href correctly, and if used to create a new anchor element,
	// will correctly populate the other location properties for reading.
	const a = document.createElement('a')
	a.href = anchor.href

	const location = {
		pathname: (a.pathname.charAt(0) === '/' ? '' : '/') + a.pathname, // IE omits leading slash
		query: url.parse(a.search),
		hash: a.hash,
	}

	if (routes(location.pathname)) {
		e.preventDefault()

		url.push(
			location.pathname,
			location.query,
			location.hash,
			!location.hash // don't scroll to top if there is a hash
		)
	}
})

// Sync with backward/forward navigation
window.addEventListener('popstate', () => url.dispatch(false))

const asyncRender = (function () {
	let cancelRender

	async function main({ child, params, location, scrollToTop }) {
		if (cancelRender) {
			cancelRender()
		}

		let isCancelled = false

		cancelRender = () => (isCancelled = true)

		let page
		let root

		const pageKey = location.pathname + location.search

		if (child?.pageData) {
			;[page, root] = await Promise.all([
				child.pageData({ params, location }),
				Root.rootData({ params, location }),
			])
		}

		// We are using a response of 'redirect' to indicate that a redirect is taking place
		// Halt the render update so that we avoid:
		// 1. showing the wrong page briefly
		// 2. sending the wrong info to the pageView event
		if (page === 'redirect') return

		if (!child || !page) {
			child = _Error404
			;[page, root] = await Promise.all([
				child.pageData(),
				root || Root.rootData({ params, location }),
			])
		}

		if (isCancelled) return

		// Since it's an SPA, we have to manually control if the page should be scrolled to top on location change
		// We want to scroll to top when moving forward (history.pushState), but restore scroll position when moving backward (history.popState)
		// The browser will save the restore scroll position automatically on history change,
		// so we want to make sure to scroll to top after the history has changed, as not to taint the restore position
		if (scrollToTop) {
			window.scrollTo(0, 0)
		}

		render(
			<Root
				child={child}
				page={page}
				root={root}
				location={location}
			/>,
			document.querySelector('#root')
		)
	}

	return main
})()

url.subscribe(async (location, scrollToTop) => {
	const route = routes(location.pathname)
	let params
	let child

	if (route) {
		const [componentFunction, parameters] = route
		params = parameters
		child = componentFunction()

		// If the child is a promise, that means that it's an asynchronously loaded module
		// Allow the module to finish loading, then take the default export as the component
		if (typeof child.then == 'function') {
			child = (await child).default
		}
	}

	asyncRender({ location, child, params, scrollToTop })
})

// Set the SPAs initial location
url.dispatch(true)
