import create from 'zustand'

export const useStore = create(() => ({
	errors: []
}))

export function displayError(error) {
	console.error(error); // todo: restrict to dev

	const errors = useStore.getState().errors;
	const msg = error.message || 'Unspecified error';
	if (errors.indexOf(msg) === -1) {
		useStore.setState({errors: [
			...errors,
			msg,
		]})
	}

	return ()=> removeError(msg);
}

export function removeError(msg) {
	useStore.setState({errors: useStore.getState().errors.filter(x => x !== msg)})
}

export function useErrors() {
	return useStore(s => s.errors);
}