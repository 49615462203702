import { h } from 'preact'
import { theme, css, cx, cn } from '../style'
import UserMenu from './UserMenu'
import { useStore } from '../store'
import PillBar from './PillBar'

export default function Header() {
	const headerContent = useStore(s => s.headerContent);

	const tabs = [
		{
			text: 'Map',
			href: '/',
		},
		{
			text: 'Dashboard',
			href: '/dash',
		},
		{
			text: 'Leases',
			href: '/leases',
		},
		{
			text: 'Customers',
			href: '/customers',
		},
		{
			text: 'Timeline',
			href: '/timeline',
		},
	]

	return (
		<div className={css`
			padding: ${theme.gutter.small};
			border-bottom: 1px solid ${theme.color.gray.light};
			display: grid;
			grid-template-columns: auto 1fr auto;
			align-items: center;
			gap: 2rem;
		`}>
			<PillBar items={tabs} pathname={location.pathname}/>
			{headerContent}
			<UserMenu/>
		</div>
	)
}
